<template>
    <div class="q-formtimeline" :style="'width:' + width">
        <div class="head">
            <h2>{{ title }}</h2>
        </div>
        <div class="body">
            <ul id="options-list">
                <li
                    v-for="(point, key) in lifeLinePoints"
                    :key="point.slug"
                    :style="`--index: ${key}`"
                    :class="[
                        {
                            selectable: point.selectable && point.able,
                            filled: progressLineSteps >= key,
                            'last-filled': progressLineSteps === key,
                            faded: !point.able && !point.noFade,
                            finalize: point.status === 'finalize',
                            'has-bullet': point.showDot,
                            connection: point.showDot || point.skippedInfo || key !== 0,
                            show: showTimeline,
                            indicator: point.isIndicator,
                        },
                    ]"
                    data-testid="point"
                    @click="clickForm(point)"
                >
                    <!-- Only show bullet in timeline for forms -->
                    <div class="bullet" :class="{ icon: point.status === 'add_new' || point.isChangingName }">
                        <!-- Add new -->
                        <div v-if="point.status === 'add_new' || point.isChangingName">
                            <q-icon
                                v-if="point.able && point.categoryId === 'intermediate-measurement'"
                                :class="[
                                    'add-form',
                                    { cancel: isAddingNewForm || point.isChangingName },
                                    { disabled: editingFormTimeline || !canAnswerIndicator(point) },
                                ]"
                                type="plusCircle"
                                @click.stop="handleAddForm(point)"
                            ></q-icon>
                            <q-tooltip v-else :disabled="point.reasons.length === 0" position="top">
                                <template v-slot:tooltip>
                                    <p>{{ point.reasons.join(', ') }}</p>
                                </template>
                                <q-icon :class="['add-form', 'disabled']" type="plusCircle"></q-icon>
                            </q-tooltip>
                        </div>

                        <!-- Default bullet -->
                        <svg
                            v-else
                            class="has-form"
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            focusable="false"
                            width="13"
                            height="13"
                        >
                            <circle stroke="none" cx="16" cy="16" r="10"></circle>
                        </svg>
                    </div>
                    <div class="row-wrapper">
                        <!-- Form related -->
                        <div v-if="point.skippedInfo" class="form-row">
                            <div class="info">
                                <q-tag v-if="point.category.skipped == true" size="medium">Overgeslagen</q-tag>
                                <p v-if="point.category.skipped == true">Tussentijdse beoordeling</p>
                            </div>
                            <div class="right">
                                <q-button
                                    v-if="point.category.skipped == true"
                                    size="xsmall"
                                    variation="ghost"
                                    @click="viewSkippedReason = true"
                                    >Bekijk toelichting</q-button
                                >
                            </div>
                        </div>
                        <!-- Enquete -->
                        <div v-else-if="point.isEnquete" class="form-row">
                            <div v-if="point.status === 'add_new' && editingFormTimeline" class="form-preview">
                                <q-tag variation="default" size="medium">Concept</q-tag>
                                <div class="loader-wrapper">
                                    <div class="loader"></div>
                                </div>
                            </div>
                            <div v-else class="info">
                                <q-tooltip :disabled="!point.tooltip">
                                    <template v-slot:tooltip>
                                        <p v-html="point.tooltip"></p>
                                    </template>
                                    <q-tag :variation="point.enqueteVariation" size="medium">{{ 
                                        point.isBeingCreated && !point.isChangingName
                                            ? 'Concept'
                                            : $t(`formStatus.${point.status}`) 
                                    }}</q-tag>
                                </q-tooltip>
                                <div v-if="point.isBeingCreated" class="create-enquete">
                                    <div class="input-container">
                                        <q-clear-input
                                            id="enquete-name-input"
                                            v-model="point.name"
                                            type="text"
                                            size="small"
                                            placeholder="Typ formuliernaam"
                                            @enter="clickNewEnquete(point)"
                                        />
                                        <p class="template-name">{{ point.template.name }}</p>
                                    </div>
                                    <q-button
                                        :disabled="!point.name.length"
                                        size="small"
                                        style="padding-left: 16px"
                                        @click.stop="clickNewEnquete(point)"
                                        >{{ point.isChangingName ? 'Wijzigen' : 'Toevoegen' }}</q-button
                                    >
                                </div>
                                <div v-else class="enquete-name">
                                    <span>{{ point.name }}</span>
                                    <span class="template-name">{{ point.template.name }}</span>
                                </div>
                            </div>
                            <div v-if="point.status !== 'add_new' && !point.isBeingCreated" class="right" :class="{ 'has-options': point.selectable }">
                                <q-tooltip :disabled="!getResponsesTooltip(point.responses)">
                                    <template v-slot:tooltip>
                                        <p v-html="getResponsesTooltip(point.responses)"></p>
                                    </template>
                                    <q-tag class="responses-tag" size="small" variation="default">
                                        <q-icon type="users-filled" color="#495057" width="16" height="16"></q-icon>
                                        <span>{{ point.responses ? point.responses.length : 0 }}</span>
                                    </q-tag>
                                </q-tooltip>
                                <q-tag v-if="point.average !== null" size="small" variation="success" class="score-tag">
                                    <q-score-text size="14" :score="point.scoreData.average" color="#37B24D"></q-score-text>
                                </q-tag>
                                <q-options
                                    v-if="point.selectable"
                                    class="options"
                                    :options="enqueteOptions"
                                    @input="handleOptionSelected($event, point)"
                                    color="rgb(174, 181, 188)"
                                ></q-options>
                            </div>
                        </div>
                        <div v-else-if="point.isForm" class="form-row">
                            <div class="info">
                                <!-- Every status a form can have needs to be defined here -->
                                <div v-if="point.status === 'add_new'" class="new-form-container">
                                    <div v-if="editingFormTimeline" class="form-preview">
                                        <q-tag variation="default" size="medium">Concept</q-tag>
                                        <div class="loader-wrapper">
                                            <div class="loader"></div>
                                        </div>
                                    </div>
                                    <q-button
                                        v-else-if="!isAddingNewForm"
                                        icon="survey"
                                        size="small"
                                        variation="ghost"
                                        @click="handleAddForm(point)"
                                        :disabled="!canAnswerIndicator(point) || !point.able"
                                        >{{ formButtonText }}</q-button
                                    >
                                    <div v-else class="select-form">
                                        <q-dropdown
                                            class="template-dropdown"
                                            size="small"
                                            v-model="selectedFormTemplateId"
                                            :options="point.options"
                                            @input="handleTemplateSelected($event, point)"
                                            placeholder="Selecteer formuliertype"
                                            :disabled="!canAnswerIndicator(point)"
                                            autocomplete
                                            autofocus
                                        ></q-dropdown>
                                        <q-button
                                            class="select-button"
                                            size="small"
                                            :disabled="!selectedFormTemplateId"
                                            @click="addEnquetePoint(point)"
                                            >{{ point.isChangingName ? 'Wijzigen' : 'Toevoegen' }}</q-button
                                        >
                                    </div>
                                </div>
                                <q-tag
                                    v-else-if="getFormInfo(point)"
                                    :variation="getFormInfo(point).variation"
                                    size="medium"
                                    >{{ getFormInfo(point).tagText }}</q-tag
                                >
                                <div v-if="point.status == 'concept' && point.able" class="fill-form-container">
                                    <p>{{ point.name }} Better Performance invullen</p>
                                </div>
                                <div v-else-if="point.status == 'concept' && !point.able">
                                    {{ point.name }} nog niet beschikbaar
                                </div>
                                <p v-else-if="!['add_new', 'finalize'].includes(point.status)">
                                    {{ getFormInfo(point).text }}
                                </p>
                            </div>
                            <div class="right flex">
                                <q-button
                                    v-if="canSkip(point)"
                                    size="xsmall"
                                    variation="ghost"
                                    @click.stop="skipRating = true"
                                    class="skip-button"
                                    >Overslaan</q-button
                                >
                                <q-tooltip v-if="point.showPublishedIcon" position="top">
                                    <template v-slot:tooltip>
                                        <p>Opdrachtgever heeft het project gepubliceerd</p>
                                    </template>
                                    <div class="question-circle">
                                        <q-icon type="chart"></q-icon>
                                    </div>
                                </q-tooltip>
                                <div class="results">
                                    <q-pill
                                        v-if="point.isPastPerformance && point.isNotConceptStatus"
                                        :values="getNumberValues(point)"
                                        size="medium"
                                        interactive
                                    ></q-pill>
                                    <div v-else>{{ point.result }}</div>
                                </div>
                            </div>
                        </div>

                        <!-- Reference letter -->
                        <div v-else-if="point.categoryId === 'reference-letter'" class="form-row">
                            <div class="info">
                                <q-tag :variation="point.referenceLetterVariation" size="medium">{{
                                    point.referenceLetterStatus
                                }}</q-tag>
                                <span>Referentiebrief</span>
                            </div>
                        </div>
                        
                        <div class="finalize" v-else-if="point.status === 'publish'">
                            <q-button
                                v-if="!isFinished"
                                variation="primary"
                                class="finishButton"
                                :disabled="!point.able"
                                @click="publish"
                                size="small"
                                >Project publiceren</q-button
                            >
                            <q-tag v-else variation="success" size="medium">Project gepubliceerd</q-tag>
                            <q-tooltip v-if="canPublish.reason" position="top">
                                <template v-slot:tooltip>
                                    <p v-html="canPublish.reason"></p>
                                </template>
                                <div class="question-circle">
                                    <q-icon type="QuestionCircle"></q-icon>
                                </div>
                            </q-tooltip>
                        </div>

                        <div class="finalize" v-else-if="point.status === 'finalize'">
                            <q-button
                                v-if="!isFinished && !point.freemium"
                                variation="primary"
                                class="finishButton"
                                :disabled="!point.able"
                                @click="finalize"
                                size="small"
                                >Project afronden</q-button
                            >
                            <q-tag v-else-if="!isFinished" variation="default" size="medium">Project afronden</q-tag>
                            <q-tag v-else variation="success" size="medium">Project afgerond</q-tag>
                            <q-tooltip v-if="canFinalize.reason" position="top">
                                <template v-slot:tooltip>
                                    <p v-html="canFinalize.reason"></p>
                                </template>
                                <div class="question-circle">
                                    <q-icon type="QuestionCircle"></q-icon>
                                </div>
                            </q-tooltip>
                        </div>

                        <div v-else-if="point.isIndicator && !indicatorHasBeenAnswered(point)" class="indicator">
                            <div v-if="point.title" :class="['title-row', point.able === false ? 'disabled' : '']">
                                <span>{{ point.title }}</span>
                                <q-tooltip v-if="point.instruction" position="top">
                                    <template v-slot:tooltip>
                                        <p v-html="point.instruction"></p>
                                    </template>
                                    <q-icon type="questionCircle"></q-icon>
                                </q-tooltip>
                            </div>
                            <p>{{ point.name }}</p>
                            <div class="answer">
                                <q-button
                                    :loading="acceptButtonLoading"
                                    variation="primary"
                                    size="small"
                                    @click="answerIndicator(point, true)"
                                    :disabled="!canAnswerIndicator(point) || point.disabled"
                                    >{{ point.acceptButtonLabel }}</q-button
                                >
                                <q-button
                                    :loading="rejectButtonLoading"
                                    variation="blank"
                                    size="small"
                                    @click="answerIndicator(point, false)"
                                    :disabled="!canAnswerIndicator(point) || point.disabled"
                                    >{{ point.rejectButtonLabel }}</q-button
                                >
                            </div>
                        </div>
                        <div v-else-if="point.isIndicator">
                            <q-tag v-if="point.answer" variation="success" size="medium">{{
                                point.acceptedLabel
                            }}</q-tag>
                            <q-tag v-else variation="default" size="medium">{{ point.rejectedLabel }}</q-tag>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <q-popup v-if="showConfirmShareModal">
            <confirm-share-modal
                @close="showConfirmShareModal = false"
                @confirm="setUseCrowFlow(true)"
            ></confirm-share-modal>
        </q-popup>

        <q-popup v-if="skipRating">
            <form-skip-review
                @inputReviewValue="getInputValueFromSkipReview"
                :settings="settings"
                @close="closePopup"
                @succes="updateProjectStatus"
            ></form-skip-review>
        </q-popup>

        <q-popup v-if="viewSkippedReason"
            ><div class="modal">
                <div class="vertical-center">
                    <h3>Tussentijdse meting overgeslagen</h3>
                </div>
                <div class="modal-content">
                    <p>
                        In dit project is de tussentijdse Better Performance meting overgeslagen op
                        <strong> {{ getDate(skippedCategorie.skippedDate) }} </strong> door
                        <strong> {{ skippedCategorie.skippedUserId }}</strong
                        >. Bekijk hieronder de gegeven toelichting.
                    </p>

                    <br />
                    <p>
                        <strong>{{ skippedCategorie.skippedReasonLabel }} </strong>
                        <br />
                        {{ skippedCategorie.skippedReason }}
                    </p>
                    <div class="footer">
                        <q-button @click="closePopupViewSkippedReason">Terug naar het project </q-button>
                    </div>
                </div>
            </div>
        </q-popup>
    </div>
</template>
<script>
/**
 * See README for documentation about the behaviour of the component
 */

import FormSkipReview from '@/pages/app/project/FormSkipReview';
import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';

import { userInitials, isValidAnswer, extractError } from '@/assets/js/utils.js';
import ConfirmShareModal from './ConfirmShareModal.vue';
import { getFullName, getDate } from '../../../assets/js/utils';

export default {
    name: 'q-formtimeline',
    components: {
        FormSkipReview,
        ConfirmShareModal,
    },
    props: {
        /**
         * full project Object.
         */
        project: {
            required: true,
        },
        /**
         * Options contains all the information regarding category names and
         * behaviour of the timeline.
         */
        options: {
            required: true,
        },
        /**
         * Settings ...
         */
        settings: {
            required: true,
        },
        /**
         * title as shown at the top of the timeline
         */
        title: {
            type: String,
            default: 'Formulieren',
        },
        /**
         * width of the component
         */
        width: {
            type: [String, Number],
            default: '100%',
        },
        /**
         * Optional discussedPsuReference shown at start of the timeline
         */
        discussedPsuReference: {
            type: Object,
        },
        useBPReference: {
            type: Object,
        },
        /**
         * Determines wether the project can be finalized. The project can be finalized when:
         * this boolean is true AND the categories requirements for finished are met
         */
        canFinish: {
            type: Boolean,
        },
        isFinished: {
            type: Boolean,
        },
        finishedDate: {
            type: Number,
        },
        /**
         * Sets all inputs to loading while fetching new data for the timeline
         */
        editingFormTimeline: {
            type: Boolean,
        },
        formTemplates: {
            type: Array,
            required: true,
        },
        showUseBPQuestion: {
            type: Boolean,
            default: false,
        },
        projectJwt: {
            type: String,
            required: true
        },
        canAdminAgree: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            user: this.$store ? this.$store.getters.getUser : {},
            acceptButtonLoading: false,
            rejectButtonLoading: false,
            skipRating: false,
            viewSkippedReason: false,
            selectedFormTemplateId: '',
            duplicatingFormId: '',
            isAddingNewForm: false,
            enquetePoint: null,
            enqueteOptions: [
                {
                    name: 'Dupliceren',
                },
                {
                    name: 'Naam wijzigen',
                },
            ],
            progressLineHeight: 0,
            progressHeight: 0,
            showTimeline: false,
            progressLineSteps: 0,
            showConfirmShareModal: false,
            cachedForms: [],
        };
    },
    methods: {
        setUseCrowFlow(useCrowFlow) {
            this.acceptButtonLoading = useCrowFlow;
            this.rejectButtonLoading = !useCrowFlow;
            this.showConfirmShareModal = false;
            
            this.$apollo.mutate({
                mutation: gql`
                    mutation($id: String!, $useCrowFlow: Boolean!) {
                        project_pdUseCrowFlow(id: $id, useCrowFlow: $useCrowFlow)
                    }
                `,
                variables: {
                    id: this.project.id,
                    useCrowFlow
                }
            })
            .then(() => {
                this.acceptButtonLoading = false;
                this.rejectButtonLoading = false;
                this.$emit('onPdUsesCrowFlowChanged', { useCrowFlow });
                this.updateProjectStatus(this.project);
            })
            .catch(error => {
                this.acceptButtonLoading = false;
                this.rejectButtonLoading = false;
                this.$store.commit('notify', extractError(error));
            })
        },
        getResponsesTooltip(responses = []) {
            const surveyResponseNames = responses
                .map(response => {
                    const { userName, email } = response.emetMeta || {};
                    if(!userName && !email) return null

                    return userName ? userName : email
                })
                .filter(name => name);
            const responsesTooltip = surveyResponseNames.join('<br>');

            return responsesTooltip
        },
        getInputValueFromSkipReview(value) {
            return value;
        },
        closePopup() {
            this.skipRating = false;
            this.$emit('statusUpdated', this.project);
        },
        closePopupViewSkippedReason() {
            this.viewSkippedReason = false;
        },
        /**
         * Answering the indicator sent in props
         */
        answerIndicator(indicator, answer) {
            if (indicator.masterId === 'discussed-psu') {
                this.acceptButtonLoading = answer;
                this.rejectButtonLoading = !answer;
                this.$emit('answerIndicator', indicator, answer);
                return;
            }

            if (answer && this.project.private) this.showConfirmShareModal = true;
            else this.setUseCrowFlow(answer);
        },
        resetIndicatorLoading() {
            this.acceptButtonLoading = false;
            this.rejectButtonLoading = false;
        },
        /**
         * Determines the tag and text to show for every state the form can be in
         * see formStatus.js for documentation about the statusses
         */
        getFormInfo(form) {
            // if(form.isEnquete) return false
            const { formStatus } = require('../form/utils');
            const { status } = formStatus(form);
            let statusLabel = this.$t(`formStatus.${form.statusLabel}`);
            // if(statusLabel.includes('status.')) statusLabel = form.statusLabel;
            const text = form.name === 'Eindmeting' ? 'Eindmeting Better Performance' : 'Tussentijdse Better Performance meting'

            if (status === 'concept') {
                return {
                    variation: 'default',
                    tagText: statusLabel || 'Concept',
                    text
                };
            } else if (status === 'startFilling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionToStart') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'filling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionFill') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'discussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'revision') {
                return {
                    variation: 'danger',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionDiscussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionRevision') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCrowAdmin') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'finished') {
                return {
                    variation: 'success',
                    tagText: statusLabel || 'Afgerond',
                    text
                };
            }

            return false;
        },
        /**
         * Checks if all dependancies are met for the category to be able to add forms
         * returns a status with boolean if all dependant requirements are met
         * and an array of reasons if not valid
         */
        categoryDependanciesMet(category) {
            let able = [];
            let reasons = [];

            if (category.dependsOn) {
                category.dependsOn.forEach((dependant) => {
                    let dependantKey = this.options.categories.map((category) => category.id).indexOf(dependant.id);

                    dependant.category = this.options.categories[dependantKey];

                    let validCount = 0;

                    dependant.category.forms.forEach((form) => {
                        if (['client', 'contractor'].includes(form.type) && dependant.status.includes(form.status))
                            validCount++;
                    });

                    const allFinished = dependant.category.forms.every((form) => form.status === 'finished');

                    able.push(validCount >= dependant.amount && (!dependant.category.allFinished || allFinished));

                    if (validCount < dependant.amount) reasons.push(dependant.category.title);
                });
            }

            return {
                status: able.every((dependant) => dependant),
                reasons,
            };
        },
        /**
         * returns status with a boolean for if its finished and reasons with an array of the categories
         * if it hasnt finished yet
         */
        categoryIsFinished(category) {
            const { finishProject, forms } = category;

            if (!finishProject) return true;
            if (!forms) return false;

            if (!this.project.usesCrowFlow) {
                if (forms.length === 0) return true;
                return (
                    forms.filter((form) => !['client', 'contractor'].includes(form.type) && form.status !== 'finished')
                        .length === 0
                );
            }

            const finishedAmount = forms.filter((form) => form.status === 'finished').length;

            return finishedAmount >= finishProject.min;
        },
        hasStartedNextCategory(categoryKey) {
            const nextCategory = this.options.categories[categoryKey + 1];
            if (!nextCategory) return false;

            const forms = nextCategory.forms || [];
            const startedForms = forms.some(form => {
                if(form.status !== 'concept' || form.responseIds?.length > 0) return true 
                const companion = form.companions ? form.companions[0] : null;
                return companion?.responseIds?.length > 0
            });

            return startedForms;
        },
        getNumberValues(point) {
            const organisationType = this.$store ? this.$store.getters.getOrganisationType : 'contractor';
            const { yourScore, companionScore } = point.results;
            const companionHasCompletedForm = !this.contains(point.companions[0].status, ['concept', 'open', 'fill']);
            const youHaveCompletedForm = !this.contains(point.status, ['concept', 'open', 'fill']);
            const yourFormSkipped = organisationType === 'contractor' && point.responses?.length === 0 && point.status === 'finished';
            const companionFormSkipped = organisationType === 'client' && point.status === 'finished' && point.companions[0].responses?.length === 0;

            const greyColor = '#f4f5f6';
            const greenColor = '#cdecd2';

            const you = organisationType === 'client' ? 'Opdrachtgever' : 'Opdrachtnemer';
            const companion = organisationType === 'client' ? 'Opdrachtnemer' : 'Opdrachtgever';

            let yourText = '';
            let yourTooltip = '';
            let yourColor = greyColor;
            let companionText = '';
            let companionTooltip = '';
            let companionColor = greyColor;

            if(yourFormSkipped) {
                yourText = '>';
                yourTooltip = `Geen score, eenzijdig afgerond door ${companion.toLowerCase()}`;
            }
            else if(youHaveCompletedForm && yourScore >= 0) {
                yourText = yourScore.toFixed(1);
                yourTooltip = `Behaalde score ${companion.toLowerCase()}`;
                if(yourScore > 5.5) yourColor = greenColor;
            }
            else if(!youHaveCompletedForm && yourScore > 0) {
                yourText = '?';
                yourTooltip = `Het formulier is nog niet vastgesteld`;
            }
            else {
                yourText = '-';
                yourTooltip = `Het formulier moet nog ingevuld worden`;
            }

            if(companionFormSkipped) {
                companionText = '>';
                companionTooltip = `Geen score, eenzijdig afgerond door ${you.toLowerCase()}`;
            }
            else if(youHaveCompletedForm && companionHasCompletedForm && companionScore >= 0) {
                companionText = companionScore.toFixed(1);
                companionTooltip = `Behaalde score ${you.toLowerCase()}`;
                if(companionScore > 5.5) companionColor = greenColor;
            }
            else if(companionHasCompletedForm) {
                companionText = '?'
                companionTooltip = `${companion} heeft al vastgesteld`;
                companionColor = greenColor;
            }
            else {
                companionText = '-';
                companionTooltip = `${companion} heeft nog niets vastgesteld`;
            }


            return [
                {
                    text: yourText,
                    color: yourColor,
                    tooltip: yourTooltip
                },
                {
                    text: companionText,
                    color: companionColor,
                    tooltip: companionTooltip
                }
            ];
        },
        clickForm(form) {
            if (form.isReferenceLetter)
                return this.$emit('clickReferenceLetter', { referenceLetterId: form.referenceLetterId });

            if (!form.isForm && !form.isEnquete) return;
            if (form.status === 'add_new' || !form.selectable) return;
            else if (form.isEnquete) this.$emit('clickEnquete', form);
            else if (form.able) this.$emit('clickForm', form);
        },
        clickNewEnquete(form) {
            if (!form.name) return;

            const nameExists =
                this.options.categories.filter(
                    (category) => category.forms.filter((_form) => _form.name === form.name).length > 0
                ).length > 0;
            if (nameExists)
                return this.$store.commit('notify', {
                    type: 'info',
                    message: 'Je kunt geen formulier aanmaken met een naam die al gebruikt is',
                });

            if (form.isChangingName) {
                this.enquetePoint = null;
                this.isAddingNewForm = false;
                form.isChangingName = false;
                form.isBeingCreated = false;
                form.selectable = true;
                this.project.forms = this.project.forms.map(projectForm => {
                    if(projectForm.id === form.id) return form
                    return projectForm
                })
                return this.$emit('enqueteUpdated', form)
            }

            if (this.duplicatingFormId) {
                this.isAddingNewForm = false;
                this.selectedFormTemplateId = '';
                this.enquetePoint = null;
                return this.$emit('duplicateEnquete', this.duplicatingFormId, form.name);
            }

            this.isAddingNewForm = false;
            this.selectedFormTemplateId = '';
            this.enquetePoint = null;
            this.$emit('clickNewEnquete', form);
        },
        handleOptionSelected(option, form) {
            if (option.name === 'Dupliceren') {
                this.duplicatingFormId = form.id;
                this.selectedFormTemplateId = form.template.id;
                form.selectable = false;
                this.isAddingNewForm = true;
                form.status = 'add_new';
                form.id = 'new'
                this.addEnquetePoint(form);
            } else if(option.name === 'Naam wijzigen') {
                this.cachedForms = [ ...this.project.forms ];
                this.selectedFormTemplateId = form.template.id;
                form.selectable = false;
                this.addEnquetePoint(form, true);
            }
        },
        clickNewMeasurement(form) {
            this.isAddingNewForm = false;
            this.selectedFormTemplateId = '';
            this.$emit('clickNewMeasurement', form);
        },
        finalize() {
            this.$emit('finalize');
        },
        publish() {
            this.$emit('publish');
        },
        indicatorHasBeenAnswered(indicator) {
            return indicator && typeof indicator.answer === 'boolean';
        },
        canEditProjectIndicator() {
            return this.projectAbility ? this.projectAbility.can('fill__Form__create') : false;
        },
        contains(value, array) {
            return array.includes(value);
        },
        canSkip(point) {
            const { pdHybridmode, contractor, organisationId } = this.project;
            const currentOrganisationId = this.$store.getters.getCurrentOrganisation.id;
            const canSkip = pdHybridmode
                ? currentOrganisationId === contractor?.id
                : currentOrganisationId === organisationId;

            return point.status == 'concept' && point.category.id == 'intermediate-measurement' && canSkip;
        },
        handleTemplateSelected(templateId, form) {
            if (!templateId) return;
            if (templateId === 'intermediate-measurement') {
                this.clickNewMeasurement(form);
                return;
            }
            this.selectedFormTemplateId = templateId;
        },
        toggleAddingForm() {
            this.isAddingNewForm = !this.isAddingNewForm;
            this.selectedFormTemplateId = '';
            this.enquetePoint = null;
        },
        handleAddForm(point) {
            if (point.isChangingName) {
                this.project.forms = [...this.cachedForms];
                this.enquetePoint = null;
                return;
            }
            if (!this.canAnswerIndicator(point)) return;
            if (this.organisationType === 'client' || this.hasCrowProduct && !this.hasQfactPD) return this.clickNewMeasurement(point);
            if (point.categoryId === 'intermediate-measurement') return this.toggleAddingForm();
        },
        async addEnquetePoint(point, editing = false) {
            const selectedTemplate = this.formTemplates.find((template) => template.id === this.selectedFormTemplateId);

            const status = point.status || 'add_new';
            const enqueteVariation = status === 'add_new' ? 'default' : this.getSurveyVariation(status);

            this.enquetePoint = {
                ...point,
                template: {
                    id: selectedTemplate.id,
                    name: selectedTemplate.name,
                },
                name: point.name || '',
                responses: [],
                status,
                enqueteVariation,
                isEnquete: true,
                isBeingCreated: true,
                isChangingName: editing,
                showDot: true
            };
            await new Promise((r) => setTimeout(r, 1));
            document.getElementById('enquete-name-input')?.focus();
        },
        getSurveyVariation(status) {
            switch (status) {
                case 'concept':
                    return 'default';
                case 'sent':
                    return 'warning';
                case 'finished':
                    return 'success';
                default:
                    return 'default';
            }
        },
        capitaliseFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        canAnswerIndicator(point) {
            return this.$parent.canEditIndicator(point);
        },
        updateProjectStatus(project) {
            this.$emit('statusUpdated', project);
        },
        getFormTooltip(form) {
            if(form.status !== 'finished') return null

            const finishedDate = getDate(form.finishedDate);
            const finishText = finishedDate ? ` op ${getDate(form.finishedDate)}` : '';
            if(form.finishedBy && form.finishedBy.id) return `${getFullName(form.finishedBy.firstName, form.finishedBy.lastName)} heeft${finishText} dit formulier afgerond`;
            return `Dit formulier is automatisch afgerond${finishText}`;
        },
        parseScore(score) {
            const oneDecimal = Math.round(score * 10) / 10;
            return oneDecimal

        }
    },
    computed: {
        getDate() {
            return getDate;
        },
        /**
         * Calculates whether finalized can be emited based on given forms
         */
        canPublish: function () {
            if (this.isFinished)
                return {
                    status: true,
                };
            let canPublish = [];

            if (!this.project.hasChosenCrowFlow && this.useBPReference)
                return {
                    status: false,
                    reason: 'Kies of je Better Performance toe wilt passen in dit project',
                };

            let BPForms = [];
            this.options.categories.forEach((category) => {
                const canFinish = this.categoryIsFinished(category);
                const measurements = category.forms.filter((form) => ['client', 'contractor'].includes(form.type));
                BPForms = [...BPForms, ...measurements];

                canPublish.push(canFinish);
            });

            if (!canPublish.every((able) => able))
                return {
                    status: false,
                    reason: 'Je kan het project pas publiceren als alle formulieren zijn afgerond',
                };
            else if (this.project.usesCrowFlow && !this.canFinish)
                return {
                    status: false,
                    reason: 'Je kan het project pas publiceren als alle verplichte projectgegevens- en kenmerken zijn ingevuld',
                };
            else if (this.organisationType !== 'client' && !this.project.private && this.project.usesCrowFlow) {
                return {
                    status: false,
                    reason: 'Alleen de opdrachtgever kan dit project publiceren omdat dit een actie is voor de CROW Beheerder',
                };
            } else if (this.project.usesCrowFlow && !this.canAdminAgree.able) {
                return {
                    status: false,
                    reason: this.canAdminAgree.reason,
                };
            }

            return {
                status: true,
            };
        },
        canFinalize: function () {
            let canFinalize = [];
            if (this.isFinished)
                return {
                    status: true,
                };

            if (!this.project.hasChosenCrowFlow && this.useBPReference)
                return {
                    status: false,
                    reason: 'Kies of je Better Performance toe wilt passen in dit project',
                };
            let BPForms = [];

            this.options.categories.forEach((category) => {
                const canFinish = this.categoryIsFinished(category);
                const measurements = category.forms.filter((form) => ['client', 'contractor'].includes(form.type));
                BPForms = [...BPForms, ...measurements];

                canFinalize.push(canFinish);
            });

            if (this.project.usesCrowFlow && BPForms.length === 0)
                return {
                    status: false,
                    reason: 'Vul de Better Performance kenmerken in om een meting te starten',
                };

            if (!canFinalize.every((able) => able))
                return {
                    status: false,
                    reason: 'Je kan het project pas afronden als alle formulieren zijn afgerond',
                };
            else if (!this.canFinish)
                return {
                    status: false,
                    reason: 'Je kan het project pas afronden als alle verplichte projectgegevens- en kenmerken zijn ingevuld',
                };

            return {
                status: true,
            };
        },
        /**
         * This method determines where and howmany points are on the timeline.
         */
        lifeLinePoints: function () {
            let points = [];

            const organisationType = this.$store ? this.$store.getters.getOrganisationType : 'contractor';

            const canEditProjectIndicator = this.canEditProjectIndicator();

            let progressLineSteps = 0;

            if (this.useBPReference) {
                const point = {
                    companions: [],
                    id: this.useBPReference.id,
                    isIndicator: true,
                    ...this.useBPReference,
                    showDot: true,
                    able: true,
                    completed: this.project.hasChosenCrowFlow,
                    drawLine: this.project.hasChosenCrowFlow,
                };

                if (!isValidAnswer(this.useBPReference.answer)) {
                    point['title'] = 'Better Performance toepassen?';
                }

                points.push(point);
            }

            /**
             * conditionally show 'discussed psu' indicator
             */
            if (this.discussedPsuReference && this.project.usesCrowFlow && this.isInitiator) {
                const point = {
                    companions: [],
                    id: this.discussedPsuReference.id,
                    isIndicator: true,
                    ...this.discussedPsuReference,
                    showDot: true,
                    able: true,
                    completed: isValidAnswer(this.discussedPsuReference.answer),
                    drawLine: true,
                };
                if (!isValidAnswer(this.discussedPsuReference.answer)) {
                    point['title'] = 'Startgesprek Better Performance';
                    point['instruction'] =
                        'Bespreek bij aanvang van het project de verwachtingen <br>en afspraken over Better Performance';
                }

                points.push(point);
            }

            this.options.categories.forEach((category, categoryKey) => {
                if (category.id === 'reference-letter' && !this.hasQfactPD) return;

                const canFinish = this.categoryDependanciesMet(category);
                const startedNextCategory = this.hasStartedNextCategory(categoryKey);

                const able = canFinish.status;
                let { reasons } = canFinish;

                if (category.skipped) {
                    points.push({
                        category,
                        skippedInfo: true,
                        completed: true,
                        showDot: true,
                    });
                }

                const { formIds = [] } = category;
                const uniqueFormIds = [ ...new Set(formIds) ]; // temporary fix, survey formIds are added twice to category.formIds on creation
                const categoryFormsInOrder = uniqueFormIds.map(formId => category.forms.find(form => form.id === formId))
                const relevantFormsInOrder = categoryFormsInOrder.filter(form => form); // companion forms have to be filtered out

                /**
                 * For every form add a point and a bullet
                 */
                const editingIndex = relevantFormsInOrder.findIndex(form => form.id === this.enquetePoint?.id);
                relevantFormsInOrder.forEach((form, index) => {
                    if(index === editingIndex) points.push(this.enquetePoint)

                    else if (form.template.answerType === 'survey') {
                        const variation = this.getSurveyVariation(form.status);
                        let attendees = form.settings?.attendees ?? [];
                        const responses = form.responses || [];
                        const average = form.scoreData?.average || null;

                        points.push({
                            isForm: true,
                            isEnquete: true,
                            showDot: true,
                            able: true,
                            canAddNew: true,
                            reasons: [],
                            responses,
                            categoryId: category.id,
                            category,
                            selectable: true,
                            ...form,
                            enqueteVariation: variation,
                            completed: form.status === 'sent',
                            drawLine: true,
                            tooltip: this.getFormTooltip(form), 
                            average
                        });
                    } else if (!form.companions || form.companions.length == 0) {
                    /**
                     * if the formtemplate has only one form without companions
                     */
                        points.push({
                            isForm: true,
                            showDot: true,
                            selectable: true,
                            able,
                            ...form,
                            category,
                            completed: form.status !== 'concept',
                            drawLine: able || form.status !== 'concept'
                        });
                    } else if (form.companions.length > 0) {
                    /**
                     * if the formtemplate is of type Better Performance (CROW) there is a companion form also.
                     * This grade has to shown on the same point.
                     */
                        let yourScore = form.score;
                        let companionScore = form.companions[0].score;

                        if (
                            form.status === 'concept' ||
                            form.status === 'open' ||
                            form.companions[0].status === 'concept' ||
                            form.companions[0].status === 'open'
                        )
                            companionScore = 0;

                        const point = {
                            isForm: true,
                            showDot: true,
                            selectable: true,
                            isPastPerformance: true,
                            isNotConceptStatus: form.status != 'concept' || form.companions[0].status != 'concept',
                            results: { yourScore, companionScore },
                            able,
                            ...form,
                            category,
                            completed: form.status === 'finished',
                            drawLine: able || form.status !== 'concept'
                        };

                        if (
                            organisationType === 'contractor' &&
                            this.project.clientStatus === 'finished' &&
                            category.id === 'final-measurement' &&
                            form.name === 'Eindmeting'
                        )
                            point['showPublishedIcon'] = true;

                        points.push(point);
                    }
                });

                const unfinishedForms = category.forms.filter((form) => form.status !== 'finished').length;

                if (unfinishedForms > 0) reasons.push('Niet alle huidige formulieren zijn afgerond.');

                if (category.id === 'reference-letter') {

                    points.push({
                        isForm: false,
                        showDot: true,
                        status: 'reference-letter',
                        able: true,
                        canAddNew: false,
                        reasons: [],
                        categoryId: category.id,
                        category,
                        referenceLetterVariation: this.project.contractorStatus === 'finished' ? 'success' : 'warning',
                        referenceLetterStatus: this.project.contractorStatus === 'finished' ? 'Afgerond' : 'Actief',
                        referenceLetterId: category.referenceLetterId,
                        selectable: true,
                        isReferenceLetter: true,
                        drawLine: true,
                        completed: true,
                    });
                }

                if (category.id === 'intermediate-measurement' && this.enquetePoint && editingIndex === -1) {
                    points.push(this.enquetePoint)
                }
            
                else if (
                    ((this.isInitiator && this.project.usesCrowFlow) ||
                    this.hasPDFullProduct) &&
                    canEditProjectIndicator &&
                    !this.isFinished &&
                    !(organisationType === 'client' && (category.skipped || startedNextCategory)) &&
                    editingIndex === -1 &&
                    category.id === 'intermediate-measurement'
                ) {
                    const intermediateMeasurementTemplate = {
                        name: 'Tussentijdse meting Better Performance',
                        id: 'intermediate-measurement',
                    };
                    let formTemplates = [];

                    const hasActiveBPMeasurement =
                        category.forms.filter(
                            (form) => ['client', 'contractor'].includes(form.type) && form.status !== 'finished'
                        ).length > 0;
                    const underMaxMeasurements = category.forms.length < category.finishProject.max;
                    const isCrowProject = this.project.usesCrowFlow;

                    if (
                        this.isInitiator &&
                        underMaxMeasurements &&
                        !hasActiveBPMeasurement &&
                        !startedNextCategory &&
                        isCrowProject
                    ) formTemplates = [intermediateMeasurementTemplate, ...this.formTemplates]
                    else formTemplates = this.formTemplates

                    const formTemplateOptions = formTemplates.map(template => {
                        return {
                            label: template.name,
                            value: template.id,
                            disabled: template.disabled,
                            tooltip: template.tooltip
                        }
                    });
        
                    const point = {
                        isForm: true,
                        showDot: true,
                        status: 'add_new',
                        able: formTemplates.length > 0 || unfinishedForms === 0,
                        canAddNew: unfinishedForms === 0,
                        reasons,
                        categoryId: category.id,
                        category,
                        options: formTemplateOptions,
                        completed: false,
                        drawLine: formTemplates.length > 0,
                    };

                    points.push(point);
                }
            });

            if (organisationType === 'client' && this.project.usesCrowFlow) {
                points.push({
                    isForm: false,
                    showDot: true,
                    status: 'publish',
                    able: this.canPublish.status && this.canAnswerIndicator && !this.isFinished,
                    noFade: this.isFinished ? true : false,
                    reasons: [],
                    drawLine: this.canPublish.status || this.isFinished,
                });
            }

            if (organisationType === 'contractor') {
                points.push({
                    isForm: false,
                    showDot: true,
                    status: 'finalize',
                    freemium: !this.hasQfactPD && !this.hasCrowProduct,
                    able: this.canFinalize.status && this.canAnswerIndicator && !this.isFinished,
                    noFade: this.isFinished ? true : false,
                    reasons: [],
                    drawLine: this.canFinalize.status || this.isFinished,
                });
            }

            points.forEach((point, index) => {
                if (point.drawLine) progressLineSteps = index;
            });

            this.progressLineSteps = progressLineSteps;

            return points;
        },
        organisationType: function () {
            return this.$store?.getters.getOrganisationType || 'contractor';
        },
        isInitiator: function () {
            return this.$store?.getters.getCurrentOrganisation.id === this.project.organisationId;
        },
        skippedCategorie() {
            const skippedCategories = this.settings.categories.find(
                (category) => category.skipped && category.skipped === true
            );

            if (skippedCategories) return { ...skippedCategories, skippedReasonLabel: 'Toelichting' };
            else return null;
        },
        hasQfactPD: function () {
            return this.$store?.getters.hasQfactPD;
        },
        hasPDFullProduct: function() {
            return this.$store?.getters.hasPDFullProduct
        },
        hasCrowProduct: function () {
            return this.$store?.getters.hasActiveQfactProducts;
        },
        formButtonText: function() {
            if(this.hasPDFullProduct) return 'Nieuw formulier'
            if(this.organisationType === 'client' || this.hasCrowProduct && this.project.usesCrowFlow) return 'Nieuwe tussentijdse meting'
        }
    },
    async mounted() {
        // trigger animation class so no animation forwards has to be used
        await new Promise((r) => setTimeout(r, 1));
        this.showTimeline = true;
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.q-formtimeline {
    .body {
        margin-block: 12px;
    }
}

.faded {
    .bullet {
        fill: $color-grey-3;
    }
    .row-wrapper .form-row .info {
        opacity: 0.8;
    }
    .tooltip {
        opacity: 1;
    }
    .finalize {
        .tag {
            opacity: 0.8;
        }
    }
}

.selectable:hover {
    background-color: lighten($color-grey-3, 5%);
    transition: 200ms ease;
    -webkit-transition: 200ms ease;

    > .tag {
        border-color: black !important;
    }

    .bullet {
        .has-form {
        }

        .add-form {
            background-color: $color-grey-3;
        }
    }

    .row-wrapper {
        .form-row {
            .info .fill-form-container .chevron {
                margin-left: 6px;
                transition: 250ms cubic-bezier(0.51, 2.5, 0.45, 1.08);
                -webkit-transition: 250ms cubic-bezier(0.51, 2.5, 0.45, 1.08);
            }
            .right {
                &.has-options {
                    transform: translateX(-24px);
                    transition: transform 400ms cubic-bezier(.51,1.6,.45,1.08);
                    -webkit-transition: transform 400ms cubic-bezier(.51,1.6,.45,1.08);
                }
                z-index: 1;

                .skip-button {
                    opacity: 1;
                    pointer-events: all;
                }
                .options {
                    transform: scale(1);
                    pointer-events: all;
                    transition: transform 350ms cubic-bezier(.51,1.6,.45,1.08);
                    -webkit-transition: transform 350ms cubic-bezier(.51,1.6,.45,1.08);
                }
            }
        }
    }
}

.selectable {
    .row-wrapper {
        .form-row {
            .responses-amount {
                position: absolute;
                right: 42px;
            }
            .right {
                display: flex;
                align-items: center;
                gap: 8px;
                transition: transform 300ms cubic-bezier(.51,1.6,.45,1.08);
                -webkit-transition: transform 300ms cubic-bezier(.51,1.6,.45,1.08);

                .skip-button {
                    position: absolute;
                    right: 12px;
                    box-shadow: 0 0 8px 2px lighten($color-grey-3, 5%);
                    opacity: 0;
                    pointer-events: none;
                }

                .responses-tag {
                    margin-top: 3px;
                    
                    span {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                .score-tag {
                    padding-inline: 8px;
                    font-weight: 600;
                    height: 22px;
                }

                .options {
                    position: absolute;
                    right: -32px;
                    padding: 8px;
                    transform: scale(0);
                    pointer-events: none;
                    transition: transform 200ms ease;
                    -webkit-transition: transform 200ms ease;
                    z-index: 1;
                }
            }
        }
    }
}

.finalize {
    display: flex;
    align-items: center;
    gap: 10px;
}

.selectable {
    transition: 200ms ease;
    -webkit-transition: 200ms ease;
    cursor: pointer;
}

.form-row {
    display: flex;
    align-items: center;
    width: 100%;

    > .info {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        .icon {
            scale: 0.95;
            translate: 0 3px;
        }

        .fill-form-container {
            display: flex;
            align-items: center;
            gap: 2px;

            .chevron {
                scale: 0.7;
                transition: 500ms ease;
                -webkit-transition: 500ms ease;
            }
        }
    }
}

.create-enquete {
    display: flex;
    gap: 16px;
    flex-grow: 1;

    .input-container {
        position: relative;
        flex-grow: 1;
        margin-top: -6px;

        // .q-clear-input {
        //     width: 100%;
        //     flex-grow: 1;
        // }

        .template-name {
            position: absolute;
            font-size: 11px;
            color: $color-grey-5;
            bottom: -8px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .button {
        width: 100px;
    }
}

.enquete-name {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 14px;
    line-height: 15px;

    > span {
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .template-name {
        -webkit-line-clamp: 1;
    }

    :nth-child(2) {
        color: $color-grey-5;
        font-size: 13px;
    }
}

.title-row {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
}

.add-form {
    position: relative;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: 300ms cubic-bezier(0.51, 1.8, 0.45, 1.08);
    -webkit-transition: 300ms cubic-bezier(0.51, 1.8, 0.45, 1.08);
    color: $color-grey-5;
    user-select: none;

    &:before {
        content: '+';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        color: white;
        background-color: $color-red;
        border-radius: 50%;
        font-size: 14px;
        line-height: 20px;
        opacity: 0;
        transition: 200ms ease;
        -webkit-transition: 200ms ease;
        overflow: hidden;
    }

    &:hover {
        color: $color-blue-dark;
    }

    &.disabled {
        color: #d6dadd;
        pointer-events: none;
    }

    &.cancel {
        rotate: 45deg;
        scale: 1.2;
        color: white;

        &:before {
            opacity: 1;
            font-size: 24px;
        }
    }
}

.new-form-container {
    width: 100%;

    .form-preview {
        display: flex;
        align-items: center;
        opacity: 0.6;
        padding-block: 1.5px;

        .loader-wrapper {
            display: flex;
            justify-content: center;
            width: 114px;

            .loader {
                $loader-color: $color-primary;
                $loader-size: 7px;
                $loader-height: 16px;
                $loader-border-size: 6px;
                $loader-gap: 18px;
                $loader-animation-duration: 1.2s;
                @import '@/components/qds/assets/loaders/loaders.scss';
                @include loader12;
            }
        }
    }

    .select-form {
        display: flex;
        gap: 6px;
        width: 100%;

        .template-dropdown {
            flex-grow: 1;
        }
        .select-button {
            width: 120px;
        }
    }
}

.has-form {
    transform: translate(0px, 0px);
}

ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

@keyframes progressHeight {
    from {
        height: 0px;
    }
    to {
        height: var(--progress-height);
    }
}

li {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 12px 12px 12px 34px;
    border-radius: 5px;
    min-height: 32px;
    cursor: default;

    &.has-bullet .bullet {
        opacity: 1;
    }

    .bullet {
        position: absolute;
        display: grid;
        place-items: center;
        left: 16.5px;
        top: 50%;
        translate: -50% -50%;
        fill: $color-grey-5;
        z-index: 2;
        opacity: 0;

        > div {
            display: grid;
        }

        &.add {
            width: 16px;
            margin-left: -22px;
            padding-right: 8px;
            color: black;
        }
    }

    .row-wrapper {
        width: 100%;

        .indicator {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .answer {
                display: flex;
                align-items: center;
                gap: 14px;
            }
        }
    }

    --animation-speed-stagger: 60ms;
    --animation-speed-stagger-slow: 140ms;
    --animation-speed-bullet: 220ms;
    --animation-speed-line: 140ms;
    --animation-speed-filled-line: 300ms;

    &.connection:before {
        content: '';
        position: absolute;
        background-color: $color-grey-3;
        width: 1px;
        inset-block-start: 0px;
        inset-block-end: 100%;
        left: 16px;
    }
    &.connection.show:before {
        inset-block-end: 0px;
        transition: var(--animation-speed-line) linear
            calc(var(--animation-speed-line) * var(--index) + (var(--animation-speed-line) / 2));
    }

    &.connection.has-bullet .bullet {
        opacity: 0;
        animation: popBullet var(--animation-speed-bullet) ease-out forwards
            calc(var(--animation-speed-stagger) * calc(var(--index)));
    }

    &.connection:after {
        content: '';
        position: absolute;
        background-color: $color-grey-5;
        width: 1px;
        inset-block-start: 0px;
        inset-block-end: 100%;
        left: 16px;
    }
    &.filled.show:after {
        inset-block-end: 0px;
        transition: var(--animation-speed-filled-line) linear
            calc(var(--animation-speed-filled-line) * var(--index) + (var(--animation-speed-filled-line)));
    }

    &.filled.show.last-filled:after {
        inset-block-end: 50%;
    }

    &.connection .row-wrapper {
        opacity: 0;
        margin-left: -24px;
    }
    &.connection.show .row-wrapper {
        margin-left: 0;
        opacity: 1;
        transition: var(--animation-speed-filled-line) ease calc(var(--animation-speed-stagger) * var(--index));
    }

    &.connection:first-of-type:before,
    &.connection:first-of-type:after {
        inset-block-start: 50% !important;
    }
    &.show.connection:last-of-type:before {
        inset-block-end: 50% !important;
    }
}

@keyframes popBullet {
    0% {
        opacity: 0;
        scale: 0.8;
    }
    75% {
        opacity: 1;
        scale: 1.2;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

h2 {
    font-weight: 500;
    font-size: 19px;
    line-height: 19px;
    margin-left: 34px;
}

.modal {
    width: 500px;
    display: flex;
    flex-direction: column;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 0 0 0;
    }
}

.modal-content {
    margin: 20px 0 0 0;
}
</style>
