<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section paddingBottom="10" paddingTop="40" hPadding="40">
                <h2>Wijzig je wachtwoord</h2>
                <p class="py-s">Vul hieronder je nieuwe wachtwoord in en druk op de knop om dit te bevestigen. </p>
            </q-section>
             <q-section class="password-form row between-xs" hPadding="40" paddingBottom="16" borderBottom>
                    <q-input labelPosition="inside" v-model="password" class="col-xs-6 password custom-gutter" inputType="password" placeholder="Wachtwoord"
                        :errorMessage="passwordOneErrorMessage"
                        :state="passwordOneState"
                        @input="checkPasswordPartials"
                        @blur="validatePassword"
                        >Wachtwoord
                         </q-input>
                    <div class="tooltip">
                           <p> Wachtwoord moet bevatten: </p>
                        <p class="frmValidation" :class="{'frmValidation--passed' : this.password.length > 7}"><q-icon type="check"/>    Acht karakters of meer</p>
                        <p class="frmValidation" :class="{'frmValidation--passed' : this.has_uppercase }"><q-icon type="check"/> Heeft een hoofdletter</p>
                        <p class="frmValidation" :class="{'frmValidation--passed' : this.has_lowercase }"><q-icon type="check"/> Heeft een kleine letter</p>
                        <p class="frmValidation" :class="{'frmValidation--passed' : this.has_number }"><q-icon type="check"/>   Heeft een nummer</p>
                        <p class="frmValidation" :class="{'frmValidation--passed' : this.has_special }"><q-icon type="check"/> Heeft een speciaal karakter</p>
                    </div>
                       
                    <q-input labelPosition="inside"
                        v-model="confirmPassword"
                        class="col-xs-6 custom-gutter"
                        inputType="password"
                        placeholder="Herhaal wachtwoord"
                        :state="passwordTwoState"
                        :errorMessage="passwordTwoErrorMessage"
                        @input="doublecheckPassword"
                        @blur="validateConfirmPassowrd"
                        >Herhaal wachtwoord</q-input>
                </q-section>
            <q-section class="space-between" vPadding="24" hPadding="40">
                <q-button to="/" variation="blank" size="medium">Naar inloggen</q-button>
                <q-button :loading="awaitPassword"  @click="changePassword">Wachtwoord instellen</q-button>
            </q-section>
            
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import Notifications from '@/components/Notifications';
import { RESETPASSWORD} from '@/graphql/mutations';
import { extractError } from '@/assets/js/utils'

export default {
    name: 'ResetPasswordValidate',
    components: {
        AuthBase,
        Notifications
    },

    data() {
        return {
            awaitPassword: false,
            token: this.$route.params.token,
            password: "",
            confirmPassword: "",
            passwordOneState: '',
            passwordTwoState: '',
            passwordOneErrorMessage: 'Wachtwoord voldoet niet',
            passwordTwoErrorMessage: '',
            has_number:   false,
            has_lowercase: false,
            has_uppercase: false,
            has_special:   false,
        }
    },

    methods: {

        checkPasswordPartials() {
            if (this.password === '') {
                this.passwordOneState = '';
                this.has_number = false;
                this.has_lowercase = false;
                this.has_uppercase = false;
                this.has_special = false;
                return false;
            }
            this.has_number = /\d/.test(this.password);
            this.has_lowercase = /[a-z]/.test(this.password);
            this.has_uppercase = /[A-Z]/.test(this.password);
            this.has_special = /[\W_]/.test(this.password);

            if (this.passwordOneState !== 'error') return;
            this.checkPassword();
        },
        checkPassword: _.debounce(function() {
            this.validatePassword();
        }, 100),
        validatePassword() {
            const valid = this.has_number && this.has_lowercase && this.has_uppercase && this.has_special;
            this.passwordOneState = !valid && this.password !== '' ? 'error' : '';
            if (valid) this.validateConfirmPassowrd();

            return valid;
        },

        doublecheckPassword: _.debounce(function() {
            if (this.passwordTwoState !== 'error') return;
            this.validateConfirmPassowrd();
        }, 100),

        validateConfirmPassowrd() {
            if (this.password === '' || this.confirmPassword === '') {
                this.passwordTwoState = '';

                return false;
            }
            if (this.password === this.confirmPassword) {
                this.passwordTwoState = 'success';
            } else {
                this.passwordTwoState = 'error';
                this.passwordTwoErrorMessage = 'De wachtwoorden zijn niet hetzelfde';
                return false;
            }
            return true;
        },

        changePassword() {
            const inputValid = this.validatePassword() && this.validateConfirmPassowrd();
            if (!inputValid) {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'De velden zijn niet correct ingevuld.'
                });
            } else {
                this.awaitPassword = true;
    
                this.$apollo
                    .mutate({
                        mutation: RESETPASSWORD,
                        variables: {
                            token: this.token,
                            password: this.password,
                            confirmPassword: this.confirmPassword,
                        }
                       
                    })
                    .then(response => {
                        this.awaitPassword = false;
                        this.$router.push('/resetpasswordsuccess')
                    })
                    .catch(err => {
                        this.awaitPassword = false;
                        this.$store.commit('notify', extractError(err))
                    });
            }

        },
    },
};
</script>


<style lang="scss" scoped>


.password:focus-within + div{
    display:block;
}

.password-form{
    position:relative;
}

.tooltip{
    display:none;
    position:absolute;
    bottom:80px;
    border: 1px solid rgb(222, 226, 230);
    border-radius:4px;
    background:white;
    height:auto;
    width: auto;
    padding:15px;
}

.icon{
    display:inline-flex;
    position:relative;
    bottom:-8px;
    
}
.frmValidation{
    font-size:11px;
    color:rgb(174, 181, 188);
}

.frmValidation--passed{
    font-size:11px;
    color:green;
}
.notifs {
    position: absolute;
    padding: 20px;
    right: 10px;
}

.custom-gutter {
     flex: 0 0 calc(100% / 2 - 1.2em);
}
</style>