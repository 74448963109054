<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section paddingBottom="10" paddingTop="40" hPadding="40" borderBottom>
                <h2>{{ title }}</h2>
                <p v-if="!this.$apollo.loading" class="py-s">
                    {{ message }}
                </p>
            </q-section>
            <q-section class="center flex-end" vPadding="24" hPadding="40">
                <q-button to="/">Doorgaan</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';
import { CONFIRM_EMAIL } from '@/graphql/mutations';
import { extractError } from '@/assets/js/utils'

export default {
    name: 'RegisterValidate',
    components: {
        AuthBase
    },

    data() {
        return {
            token: this.$route.params.token,
            success: false
        };
    },
    computed: {
        title() {
            return this.success ? 'E-mailadres gevalideerd' : 'Er ging iets mis';
        },
        message() {
            return this.success
                ? 'Je e-mailadres is succesvol gevalideerd. Klik op de knop hieronder om door te gaan.'
                : 'De validatielink die je hebt gebruikt is ongeldig. Klik op de validatieknop in de mail of log in om je e-mailadres opnieuw te laten valideren.';
        }
    },
    mounted() {
        this.$apollo
            .mutate({
                mutation: CONFIRM_EMAIL,
                variables: {
                    token: this.token
                }
            })
            .then(response => {
               
                this.success = true;
            })
            .catch(err => {

                this.$store.commit('notify', extractError(err))
            });
    }
};
</script>
