<template>
    <div class="page-padding">
        <div class="flex space-between mb-l header-height">
            <h2>Bedrijf zoeken</h2>
        </div>
        <q-table-with-pagination
            :data="organisations"
            :columns="columns"
            :tableLoading="loadingOrganisations"
            :maxRows="paginatedTableLength"
            :dataLength="companiesMinimal.length"
            :isSearching="Boolean(selectedFiltersLength)"
            clickable
            @filterUpdated="filterUpdated"
            @filterSearch="_filterSearchChanged"
            @pageSelection="handleTablePagination"
            @filterInitialized="filterUpdated"
            ref="companiesTable"
        >
            <template v-slot:row="{ row, column }">
                <span class="vertical-center" v-if="column == 'name'">
                    <q-avatar size="small" :img="row.logo">{{ row.name ? row.name.charAt(0) : '' }}</q-avatar>
                    <p class="bold" style="cursor:pointer">{{ row.name }}</p>
                </span>
                <span v-else-if="column == 'kvk'">
                    <p v-if="row.legal">{{ row.legal.registrationId }}</p>
                </span>
                <span v-else-if="column == 'branches'">
                    <p>{{ row.childs ? row.childs.length : 0 }}</p>
                </span>
                <span v-else-if="column == 'phone'">
                    <p>{{ getMainPhoneNumber(row.phones) }}</p>
                </span>
                <span v-else-if="column == 'worksAverage'">
                    <q-tooltip :disabled="row.worksAverage != null">
                        <template v-if="!row.worksAverage" #tooltip> Deze organisatie heeft nog geen<br>afgeronde werken. </template>
                        <q-tag 
                            :emoji="getSmileyForCrowScore(row.worksAverage)" 
                            :emojiValue="row.worksAverage">
                        </q-tag>
                    </q-tooltip>
                </span>
                <span v-else-if="column == 'servicesAverage'">
                    <q-tooltip :disabled="row.servicesAverage != null">
                        <template v-if="!row.servicesAverage" #tooltip> Deze organisatie heeft nog geen<br>afgeronde diensten. </template>
                        <q-tag
                            :emoji="getSmileyForCrowScore(row.servicesAverage)"
                            :emojiValue="row.servicesAverage"
                        ></q-tag>
                    </q-tooltip>
                </span>
                <span v-else :class="column == 'name' ? 'bold' : ''">{{ row[column] }}</span>
            </template>
        </q-table-with-pagination>
    </div>
</template>

<script>
import _ from 'lodash';
import { getSmileyForCrowScore, getCalculatedRows } from '@/assets/js/utils';
import { ORGANISATIONS_COMPANIES_PAGE, ORGANISATIONS_MINIMAL_COMPANIES_PAGE } from '@/graphql/queries';

export default {
    name: 'Companies',
    data() {
        return {
            filter: {
                status: 'all'
            },
            loadingOrganisations: false,
            organisations: [],
            organisationOptions: [],
            kvkOptions: [],
            whereQuery: {},
            sorting: [],
            paginatedTableLength: 10,
            paginatedCompanies: [],
            companiesMinimal: [],
            allCompaniesMinimal: [],
            selectedFiltersLength: 0
        };
    },
    methods: {
        filterUpdated(filter) {
            if(Object.keys(filter).length) {
                const filters = Object.keys(filter).filter(filterName => {
                    if(typeof filter[filterName].filter === 'array' && filter[filterName].filter.length > 0) return true
                    if(typeof filter[filterName].filter === 'object' && Object.keys(filter[filterName].filter).length > 0) return true
                });
                this.selectedFiltersLength = filters.length;
                try {
                    this.$refs.companiesTable.resetCounter();
                } catch(error) {}
            }

            let query = {};

            this.whereQuery = {
                AND: [
                    ...Object.keys(filter)
                        .filter(field => {
                            return filter[field].filter.length > 0;
                        })
                        .map(field => {
                            switch (field) {
                                case 'name':
                                    return {
                                        id__in: filter[field].filter
                                    };
                                case 'kvk':
                                    return {
                                        id__in: filter[field].filter
                                    };
                                case 'worksAverage':
                                    if (filter[field].filter.length !== 2) return {};

                                    if (filter[field].filter[1] === 0)
                                        filter[field].filter[1] = 0.1;

                                    return {
                                        worksAverage__gte: filter[field].filter[0],
                                        worksAverage__lte: filter[field].filter[1]
                                    }
                                case 'servicesAverage':
                                    if (filter[field].filter.length !== 2) return {};

                                    if (filter[field].filter[1] === 0)
                                        filter[field].filter[1] = 0.1;

                                    return {
                                        servicesAverage__gte: filter[field].filter[0],
                                        servicesAverage__lte: filter[field].filter[1]
                                    }
                                default:
                                    return {};
                            }
                        }),
                    query
                ]
            };

            let sorting = [];

            this.sorting = Object.keys(filter).map(field => {
                switch (field) {
                    case 'date':
                        sorting.push(`creationDate__` + filter[field].sorting);
                        break;
                    default:
                        const sort = `${field}__${filter[field].sorting}`;
                        sorting.push(sort);
                        break;
                }
            });
            
            if (sorting.length === 0) sorting = ['worksAverage__DESC', 'servicesAverage__DESC']

            this.sorting = sorting;

            this.get();
            this.getCompaniesMinimal();
        },
        _filterSearchChanged: _.debounce(function(data) {
            return this.filterSearchChanged(data);
        }, 500),
        filterSearchChanged({ column, filterData }) {
            const { search = '' } = filterData;
            if (search.length === 0) return;

            switch (column) {
                case 'name':
                    this.getOrganisationFilterOptions(search, undefined);
                    break;
                case 'kvk':
                    this.getOrganisationFilterOptions(undefined, search);
                    break;
                default:
                    break;
            }
        },
        getSmileyForCrowScore(avg) {
            return getSmileyForCrowScore(avg);
        },
        _get: _.debounce(function(skip, first) {
            return this.get(skip, first);
        }, 500),
        get(skip = 0, first = this.paginatedTableLength) {
            const where = {
                AND: [
                    { type: 'main' },
                    { status__contains: this.filter.status === 'all' ? '' : this.filter.status },
                    { products__some: { slug__contains: 'contractor' } },
                    { OR: [
                        { worksAverage__gte: 0 }, 
                        { worksAverage: null },
                        { servicesAverage__gte: 0 },
                        { servicesAverage: null }
                    ] }
                ]
            };

            this.loadingOrganisations = true;

            if (Object.keys(this.whereQuery).length > 0 && this.whereQuery.AND && this.whereQuery.AND.length > 0) {
                this.whereQuery.AND.forEach(query => {
                    if (Object.keys(query).length > 0) where.AND.push(query);
                });
            }

            this.$apollo
                .query({
                    query: ORGANISATIONS_COMPANIES_PAGE,
                    variables: {
                        skip,
                        first,
                        sort: this.sorting[0],
                        where
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.loadingOrganisations = false;
                    const organisations = result.data.organisations.map(organisation => {
                        organisation.to = `/companies/${organisation.id}`;
                        return organisation
                    })
                    this.organisations = organisations;
                })
                .catch(err => {
                    this.loadingOrganisations = false;
                });
        },
        getMainPhoneNumber(phoneList) {
            if (!phoneList || phoneList.length === 0) return;
            const mainPhone = phoneList.filter(phone => phone.main);
            return mainPhone[0].phone || null;
        },
        getOrganisationFilterOptions(term, kvk) {
            this.$apollo
                .query({
                    query: ORGANISATIONS_MINIMAL_COMPANIES_PAGE,
                    variables: {
                        where: {
                            AND: [
                                { type: 'main' },
                                { products__some: { slug__contains: 'contractor' } },
                                term ? { name__contains: term } : {},
                                kvk ? { legal_registrationId__contains: kvk } : {}
                            ]
                        },
                        sort: 'name',
                        caseSensitive: false
                    },
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    this.organisationOptions = result.data.organisations
                    .map(organisation => {
                        return {
                            value: organisation.id,
                            label: organisation.name
                        };
                    });

                    this.kvkOptions = result.data.organisations
                        .map(organisation => {
                            return {
                                value: organisation.id,
                                label: organisation.legal ? organisation.legal.registrationId : '-'
                            };
                        });

                    this.kvkOptions = this.kvkOptions.filter(
                        (option, key) => this.kvkOptions.map(option => option.value).indexOf(option.value) === key
                    );
                })
                .catch(err => {
                    console.error(err);
                });
        },
        getCompaniesMinimal(initialize = false) {
            const where = {
                AND: [
                    { type: 'main' },
                    { status__contains: this.filter.status === 'all' ? '' : this.filter.status },
                    { products__some: { slug__contains: 'contractor' } },
                    { OR: [
                        { worksAverage__gte: 0 }, 
                        { worksAverage: null },
                        { servicesAverage__gte: 0 },
                        { servicesAverage: null }
                    ] }
                ]
            };

            if (Object.keys(this.whereQuery).length > 0 && this.whereQuery.AND && this.whereQuery.AND.length > 0) {
                this.whereQuery.AND.forEach(query => {
                    if (Object.keys(query).length > 0) where.AND.push(query);
                });
            }

            this.$apollo.query({
                query: ORGANISATIONS_MINIMAL_COMPANIES_PAGE,
                variables: {
                    where
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                if(initialize) {
                    this.allCompaniesMinimal = result.data.organisations;
                    if(this.companiesMinimal.length === 0) this.companiesMinimal = result.data.organisations;
                } else this.companiesMinimal = result.data.organisations
            })
            .catch(error => console.log(error))
        },
        handleTablePagination(selectedPageIndex) {
            const first = this.paginatedTableLength;
            const skip = this.paginatedTableLength * (selectedPageIndex-1);

            this.get(skip, first);
        }
    },
    computed: {
        columns: function() {
            return [
                {
                    field: 'name',
                    label: 'Bedrijfsnaam',
                    width: '250px',
                    loadingStyle: 'avatar_text',
                    filter: true,
                    filterType: 'checkbox',
                    filterOptions: this.organisationOptions
                },
                {
                    field: 'kvk',
                    label: 'KvK',
                    width: '80px',
                    loadingWidth: '40%',
                    filter: true,
                    filterType: 'checkbox',
                    filterOptions: this.kvkOptions
                },
                {
                    field: 'branches',
                    label: 'Vestigingen',
                    width: '80px',
                    loadingWidth: '15%'
                },
                {
                    field: 'phone',
                    label: 'Telefoon',
                    width: '100px'
                },
                {
                    field: 'finishedProjects',
                    label: 'Projecten',
                    width: '80px',
                    title: 'Aantal afgeronde projecten'
                },
                {
                    field: 'worksAverage',
                    label: 'Werken',
                    width: '80px',
                    loadingStyle: 'badge',
                    loadingWidth: '40%',
                    overflow: true,
                    filter: true,
                    filterType: 'score'
                },
                {
                    field: 'servicesAverage',
                    label: 'Diensten',
                    width: '80px',
                    loadingStyle: 'badge',
                    loadingWidth: '40%',
                    overflow: true,
                    filter: true,
                    filterType: 'score'
                }
            ];
        }
    },
    created() {
        this.getCompaniesMinimal(true);
        this.getOrganisationFilterOptions();
    },
    mounted() {
        this.paginatedTableLength = getCalculatedRows(10);
    }
};
</script>

<style lang="scss" scoped>
.page-padding {
    padding-bottom: 0;
}
</style>
