<template>
    <div class="row middle-xs center-xs auth-base">
        <slot />
        <img class="qfact-logo-bottom" src="/statics/img/Qfact-logo.svg" alt="" />
        <notifications />
    </div>
</template>

<script>
import Notifications from '@/components/Notifications';

export default {
    name: 'AuthBase',
    components: {
        Notifications
    }
};
</script>

<style>
.auth-base {
    min-height: 100vh;
}

.qfact-logo-bottom {
    position: fixed;
    bottom: 48px;
    height: 40px;
    z-index: -1;
}
</style>
