<template>
    <div class="enquete-answer">
        <div class="head">
            <div class="left">
                <span class="grey-label">Vraag {{ index }}</span>
                <p class="name">{{ indicator.name }}</p>
            </div>
            <div class="right">
                <jump-transition>
                    <q-tooltip v-if="!selectedResponseId && indicator.responses.length> 0" :disabled="!indicator.responses || indicator.responses.length === 0" position="top">
                        <template v-slot:tooltip>
                            <div v-for="item in tooltipItems" v-html="item" style="display: flex; gap: 12px; justify-content: space-between; white-space: nowrap; overflow: hidden; text-overflow:ellipsis;"></div>
                        </template>
                        <div class="amount-container">
                            <p class="amount">{{ indicator.responses.length }}</p>
                            <q-icon class="users-icon" type="users-filled"></q-icon>
                            <q-score-text v-if="indicator.average" :score="indicator.average" class="score-text"></q-score-text>
                        </div>
                    </q-tooltip>
                </jump-transition>
            </div>
        </div>

        <div v-if="indicator.description" class="description">
            <p v-html="indicator.description"></p>
        </div>

        <div class="content">
            <div v-if="['checkbox','score','radio'].includes(indicator.displayType)" class="question-type">
                <div class="options-container" :class="indicator.answerValues.length > 6 ? 'wrap-options' : 'row-options'">
                    <div 
                        v-for="answerValue in indicator.answerValues"
                        :key="answerValue.value" 
                        class="option"
                        :class="{ selected: answerValue.percentage > 0}"
                        :style="`--percentage: ${answerValue.percentage}%`">
                        <div class="left">
                            <span class="label">
                                <span class="value" v-if="indicator.displayType === 'score' && answerValue.value !== -1">{{ answerValue.value }} - </span>
                                {{ answerValue.label }}
                            </span>
                            
                            <q-avatar-group :images="getUsers(answerValue.responses)" color="lightgrey"></q-avatar-group>
                        </div>
                        <div class="right">
                            <p class="percentage">{{ answerValue.percentage }}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-answers">
                <div v-for="response, index in indicator.responses" :key="index" class="answer">
                    <p class="name">{{ getFullName(response.user) }}</p>
                    <span class="response">
                        <p v-if="response.value === -1">Niet van toepassing</p>
                        <p v-else-if="indicator.answerType === 'date'" class="value">{{ getDate(response.value) }}</p>
                        <a v-else-if="indicator.answerType === 'file'" class="value" :href="response.value"></a>
                        <p v-else-if="indicator.displayType === 'currency'" class="value">{{ getMoney(response.value) }}</p>
                        <p v-else class="value">{{ response.value }}</p>
                        <p v-if="indicator.displayType === 'numberUnit' && response.value !== -1 && indicator.displayType !== 'currency'" class="unit">{{ indicator.unit }}</p>
                    </span>
                </div>
            </div>
        </div>
        <div class="comments">
            <div v-for="response, index in commentResponses" :key="index" class="comment-wrapper">
                <div class="comment">
                    <p class="name">{{ getUserLabels([response]) }}</p>
                    <p class="text" v-html="response.improvement"></p>
                </div>
            </div>
            <div v-if="commentResponses.length === 0" class="grey-label">Geen opmerking{{ !selectedResponseId ? 'en' : '' }}</div>
        </div>
    </div>
</template>

<script>
import { getDate, getMoney, userInitials } from '@/assets/js/utils.js';

export default {
    name: 'enquete-answer',
    props: {
        indicator: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        selectedResponseId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            commentResponses: []
        }
    },
    methods: {
        getDate(unix) {
            if(!unix) return ''
            return getDate(unix)
        },
        getMoney(value) {
            return getMoney(value)
        },
        getFullName(user) {
            return `${user.firstName} ${user.lastName}`
        },
        getUsers(responses) {
            return responses.map(response => {
                let fallback = userInitials(response.user.firstName, response.user.lastName);
                const noName = response.user.lastName === 'Respondent';
                if(noName) fallback = response.user.firstName.replace('e','');
                return {
                    avatar: response.user.avatar,
                    fallback,
                    tooltip: this.getFullName(response.user)
                }
            })
        },
        getUserLabels(responses) {
            const users = responses.map(response => this.getFullName(response.user));
            return users.join('<br>')
        },
        setComments() {
            let responses = this.indicator.responses;
            this.commentResponses = responses.filter(response => response.improvement);
        }
    },
    computed: {
        tooltipItems: function() {
            const tooltipItems = [];
            if(!this.indicator.responses) return
            this.indicator.responses.forEach(response => {
                let suffix = '';
                if(response.scoreData) {
                    const indicatorScoreData = response.scoreData.indicatorScores.find(indicatorScore => indicatorScore.indicatorId === this.indicator.id);
                    if(indicatorScoreData) suffix = `<b>${indicatorScoreData.average}</b>`;
                }
                tooltipItems.push(`<span>${this.getFullName(response.user)}</span>${suffix}`);
            });
            return tooltipItems
        }
    },
    created() {
        this.setComments();
    },
    watch: {
        selectedResponseId: function() {
            this.setComments();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.enquete-answer {
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;

            .name {
                font-weight: 500;
                font-size: 15px;
            }
        }

        .amount-container {
            display: flex;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            background: $color-grey-1;
            border-radius: 6px;
            color: $color-grey-9;
            padding: 2px 8px 2px 6px;
            cursor: default;

            .users-icon {
                color: $color-grey-9;
            }

            .icon {
                position: relative;
                scale: 1;
            }

            .amount {
                font-weight: 600;
                font-size: 15px;
            }
            .score-text {
                position: relative;
                padding-left: 8px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 2px;
                    top: 0%;
                    width: 1px;
                    height: 100%;
                    border-radius: 1px;
                    background: $color-grey-9;
                }
            }
        }
    }

    .description {
        margin-top: 18px;
    }

    .comments {
        --gap: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap);

        .comment-wrapper {
            flex-basis: calc(50% - (0.5 * var(--gap)));

            .comment {
                --padding: 16px;
                background-color: #D2EEF0;
                border-radius: 4px;
                padding: 16px;
    
                .name {
                    font-weight: 500;
                }
                .text {
                    margin-top: 8px;
                }
            }
        }


        .no-comments {
            color: #495057;
            font-weight: 500;
            font-size: 12px;
            background-color: #F2F2F2;
            border: 1px solid #DEE2E6;
            border-radius: 4px;
            padding: 6px 10px;
        }
    }
}

.grey-label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    background: #f2f2f2;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    color: $color-grey-7;
    padding: 4px 6px;
    user-select: none;
    cursor: default;
    white-space: nowrap;
}

.content {
    margin-block: 20px;
}

.text-answers {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .answer {
        display: flex;
        gap: 8px;

        .name {
            flex-grow: 1;
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .response {
            display: flex;
            flex-grow: 1;

            p {
                font-weight: 500;
            }

            .unit {
                margin-left: 4px;
                font-size: 12px;
            }
        }
    }
}

.options-container {
    --gap: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap) calc(var(--gap) * 2);
    width: 100%;
    user-select: none;

    &.wrap-options {
        .option {
            width: calc(50% - var(--gap) - var(--padding-inline) * 2);
        }
    }

    &.row-options {
        .option {
            width: 100%;
        }
    }

    .option {
        --padding-inline: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        background-color: #F5F5F5;
        font-size: 14px;
        border-radius: 4px;
        padding: 14px var(--padding-inline);
        cursor: default;
        isolation: isolate;

        &.selected {
            font-weight: 500;
            color: $color-blue-darker;
        }

        .left {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 8px;

            .percentage {
                font-weight: 500;
            }
        }

        &:before {
            content:"";
            position: absolute;
            inset-block: 0;
            width: var(--percentage);
            left: 0;
            top: 0;
            background-color: #D2EEF0;
            border-radius: 4px;
            transition: .6s cubic-bezier(.65,.05,.35,.95);
            z-index: -1;
        }
    }
}


</style>