<template>
    <jump-transition>
        <div class="q-card-small">
            <q-section
                hPadding="40"
                paddingTop="24"
                paddingBottom="24"
                borderBottom
                class="text-align-center"
                :style="bottomMargin"
            >
                <q-profile-info
                    id="step0"
                    size="large"
                    variation="top"
                    :firstName="user.firstName"
                    :lastName="user.lastName"
                    :role="user.email"
                    :image="user.avatar"
                ></q-profile-info>

                <div class="margin-top" style="display:flex;justify-content:center;position:relative">
                    <q-button to="/access/profile" variation="ghost" size="small">Profiel beheren</q-button>
                    <span v-if="notificationAmount > 0" class="notifications-span">{{ notificationAmount }}</span>
                </div>
            </q-section>

            <!-- permanent alert -->
            <q-section
                v-if="user && user.status === 'new'"
                hPadding="40"
                paddingTop="4"
                paddingBottom="4"
                class="permanent-alert"
            >
                <q-icon type="warning"></q-icon>
                <p class="permanent-alert-text">Je e-mailadres is nog niet gevalideerd</p>
            </q-section>

            <user-organisations
                :organisations="organisations"
                :loading="loadingOrganisations"
                xPadding="40"
            ></user-organisations>

            <q-section
                v-for="organisation in potentialOrganisations"
                :key="organisation.id + '-potential'"
                borderTop
                class="space-between padding expand"
            >
                <div class="flex">
                    <div class="lock">
                        <q-icon type="lock-closed" width="15" height="15" color="#ADB5BD" class="icon" />
                    </div>
                    <q-organization-info
                        :organization="organisation"
                        :address="address(organisation.address)"
                        :image="organisation.logo"
                        @click="select($event, organisation.id)"
                    ></q-organization-info>
                </div>
                <div class="checkbox">
                    <q-checkbox
                        :value="selected.includes(organisation.id)"
                        @input="select($event, organisation.id)"
                    ></q-checkbox>
                </div>
            </q-section>

            <div id="step1">
                <q-section
                    hPadding="40"
                    vPadding="15"
                    borderTop
                    class="add-organisation-row"
                    :class="{ disabled: user.status !== 'active' }"
                >
                    <q-icon @click="registerOrganisation" type="plus-circle" width="32" height="32" :color="iconColor" class="icon" />
                    <div @click="registerOrganisation">
                        <p class="main">Organisatie aanmelden</p>
                        <p class="sub">Registreer een nieuwe organisatie</p>
                    </div>
                </q-section>
            </div>

            <q-section class="footer" hPadding="40" vPadding="20" borderTop>
                <div class="logout">
                    <q-icon type="power" width="18" height="18" class="icon" />
                    <p @click="logout">Uitloggen</p>
                </div>
                <div class="add-button" v-if="potentialOrganisations.length > 0">
                    <q-button variation="primary" size="small" :disabled="selected.length === 0" @click="access"
                        >Toegang vragen</q-button
                    >
                </div>
            </q-section>
        </div>
        <!-- <div id="step3" @click="startTour">
            <q-icon class="tourButton" type="questionCircle" width="30" height="30"> </q-icon>
        </div> -->
    </jump-transition>
</template>

<script>
import { USER_POTENTIAL_ORGANISATIONS, REQUEST_ORGANISATION_ACCESS } from '@/graphql/mutations';
import { ORG_MINE, GET_USER } from '@/graphql/queries';
import { getAddress, extractError } from '@/assets/js/utils';
import UserOrganisations from '@/components/UserOrganisations';
import uxTour from '@/assets/js/uxTour';

export default {
    name: 'SelectOrg',
    components: { UserOrganisations },
    data() {
        return {
            activeOrganisations: [],
            newOrganisations: [],
            potentialOrganisations: [],
            iconColor: '#22B8CF',
            hoveredOrg: null,
            tour: {
                steps: [
                    { element: 'step0', text: 'Welkom bij Qfact!' },
                    {
                        element: 'step1',
                        text: 'Selecteer hier je bedrijf, of meld je bedrijf aan',
                        direction: 'top'
                    },
                    {
                        element: 'step3',
                        text: 'Je kan de rondleiding altijd starten door op dit icoontje te drukken',
                        direction: 'top'
                    }
                ]
            },
            selected: [],
            notificationAmount: 0,
            loadingOrganisations: true
        };
    },
    methods: {
        startTour() {
            const uxtour = new uxTour();
            uxtour.start(this.tour);
        },
        orgHover(id) {
            this.hoveredOrg = id;
        },
        orgStopHover(id) {
            if (this.hoveredOrg === id) {
                this.hoveredOrg = null;
            }
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        },
        requestOrganisationAccess() {
            this.$router.push('/access/organisations');
        },
        registerOrganisation() {
            window.location.href = `${process.env.EMVI_APP_URL}/opdrachtnemers/create`
        },
        getAddress(address) {
            return getAddress(address);
        },
        getEmailExtension(email) {
            return email.split('@')[1];
        },
        address(address) {
            const parsedAddress = getAddress(address);

            return parsedAddress;
        },
        select(e, id) {
            const index = this.selected.indexOf(id);

            if (index === -1) this.selected.push(id);
            else this.selected.splice(index, 1);
        },
        access() {
            let v = this;

            this.selected.forEach(id => {
                const selectedOrg = this.potentialOrganisations.find(org => org.id === id);

                this.$apollo
                    .mutate({
                        mutation: REQUEST_ORGANISATION_ACCESS,
                        variables: {
                            id: this.user.id,
                            organisationId: id
                        }
                    })
                    .then(response => {
                        if (response.data.user_requestOrganisationAccess) {
                            v.potentialOrganisations.forEach((org, key) => {
                                if (org.id === id) {
                                    const you = this.user;
                                    you.userId = you.id;
                                    you.role = {
                                        name: 'User'
                                    }
                                    you.status = 'requested';
                                    selectedOrg.users.push(you);
                                    v.newOrganisations.push(selectedOrg);
                                    v.potentialOrganisations.splice(key, 1);
                                }
                            });

                            const emailExtension = this.getEmailExtension(this.user.email);
                            const accessWithWhitelistedEmail = selectedOrg.whitelistedEmails
                                ? selectedOrg.whitelistedEmails.includes(emailExtension)
                                : false;
                            const autoApprove =
                                accessWithWhitelistedEmail &&
                                selectedOrg.settings &&
                                selectedOrg.settings.login &&
                                selectedOrg.settings.login.whitelistedAutoApprove;

                            const message = autoApprove
                                ? `Uw verzoek om toegang tot ${selectedOrg.name} is automatisch goedgekeurd op basis van uw e-mailadres.`
                                : `U heeft een verzoek ingediend. Wacht een reactie af van een beheerder.`;
                            this.$store.commit('notify', {
                                type: 'info',
                                message
                            });
                        }
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            });
        },
        getOrganisations() {
            this.loadingOrganisations = true;

            this.$apollo.query({
                query: ORG_MINE,
                fetchPolicy: 'no-cache',
            })
            .then(result => {
                this.loadingOrganisations = false;
                const vm = this;
                if (result.data.org_mine) {
                    const orgs = result.data.org_mine || [];
                    const activeOrgs = [];
                    const newOrgs = [];
                    orgs.forEach(org => {
                        const user = org.users.find(user => user.userId === vm.$store.getters.getUser.id);
                        if (org.status == 'active' && user.status === 'active') {
                            activeOrgs.push(org);
                        } else if (user.status === 'requested' || (user.status === 'active' && org.status === 'new')) {
                            newOrgs.push(org);
                        }
                        vm.activeOrganisations = activeOrgs;
                        vm.newOrganisations = newOrgs;
                    });
                }
            })
            .catch(error => {
                this.loadingOrganisations = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van jouw organisaties' })
            })
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        organisations() {
            return [...this.activeOrganisations, ...this.newOrganisations];
        },
        potentialOrgFilter() {
            const whitelistedEmails__contains = this.$store.getters.getUser.email.split('@')[1] || 'invalid-email-extension';
            
            return {
                where: {
                    AND: [
                        { whitelistedEmails__contains },
                        { users__none: { userId: this.$store.getters.getUser.id } },
                        { type: 'main' }
                    ]
                }
            };
        },
        goToDashboard() {
            return this.hoveredOrg === organisation.id && organisation.status === 'active' ? 'Naar dashboard' : null;
        },
        bottomMargin() {
            return this.organisations && this.organisations.length === 0 ? { marginBottom: '-1px' } : {};
        }
    },
    created() {
        this.getOrganisations();
    },
    apollo: {
        fetchPotentialOrganisations: {
            query: USER_POTENTIAL_ORGANISATIONS,
            variables() {
                return this.potentialOrgFilter;
            },
            update: data => data.organisations,
            fetchPolicy: 'no-cache',
            result(res) {
                if (res.data.organisations) {
                    if (res.data.organisations && res.data.organisations.length > 0) {
                        this.potentialOrganisations = res.data.organisations;
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';
$color-orange-lighter: #f3decc;
$color-orange-light: #fde1c9;
$color-orange: rgb(253, 126, 23);

.footer {
    display: flex;
}

.permanent-alert {
    display: flex;
    align-items: center;
    background-color: $color-orange-light;
    color: $color-orange;
    border-color: $color-orange-light !important;

    & .permanent-alert-text {
        font-weight: 500;
        font-size: 0.8rem;
        margin-left: 17px;
    }

    & .icon {
        margin-top: 5px;
        margin-left: 8px;
    }
}

.padding {
    padding: 8px 28px 8px 19px !important;
    position: relative;
}

.checkbox input[type='checkbox'] {
    margin: 0 !important;
}

.checkbox {
    padding: 7px 0 0 0;
}

.tag {
    padding: 10px 0 0 0;
    position: absolute;
    right: 20px;
}

.lock {
    padding-top: 13px;
    margin-right: 6px;
    min-width: 15px;
}

.margin-top {
    margin-top: 11px;
}

.add-organisation-row {
    display: flex;
    flex-direction: row;
}
.add-organisation-row > div {
    cursor: pointer;
}
.add-organisation-row > div > p.main {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.add-organisation-row > div > p.sub {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: -3px;
}
.add-organisation-row > .icon {
    margin: 3px 12px 0 0;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.logout {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    cursor: pointer;

    .icon {
        margin: 3px 16px 0px 8px;
    }

    &:hover {
        color: rgb(187, 0, 0);
        transition: color 0.5s;
    }
}

#step3 {
    position: absolute;
    right: 37px;
    bottom: 42px;
    cursor: pointer;
}

.tourButton {
    color: #aeb5bb;
    transition: color ease 200ms;

    &:hover {
        color: #22b8cf;
        transition: color ease 200ms;
    }
}

.notifications-span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-orange;
    color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 12px;
    font-family: 'Gotham';
    top: -4px;
    transform: translateX(330%);
}
</style>
