import Vue from 'vue';
import Router from 'vue-router';
import { store } from '../store';
import Ability from '../assets/js/ability';

// App routes
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import TwoFactor from '@/pages/auth/TwoFactor';
import TwoFactorConfig from '@/pages/auth/TwoFactorConfig';
import RegisterConfirm from '@/pages/auth/RegisterConfirm';
import RegisterValidate from '@/pages/auth/RegisterValidate';
import ResetPassword from '@/pages/auth/ResetPassword';
import ResetPasswordConfirm from '@/pages/auth/ResetPasswordConfirm';
import ResetPasswordValidate from '@/pages/auth/ResetPasswordValidate';
import ResetPasswordSuccess from '@/pages/auth/ResetPasswordSuccess';
import AutoResetPassword from '@/pages/auth/AutoResetPassword';
import AccessWithRequest from '@/pages/auth/AccessWithRequest';
import EnqueteFill from '@/pages/app/enquete/EnqueteFill';

import AccessBase from '@/pages/access/AccessBase';
import AccessRoutes from './access';

import AppBase from '@/pages/app/AppBase';
import AppRoutes from './app';

import ResetEmail from '@/pages/access/ResetEmail';
import Logout from '@/pages/auth/Logout';
import SsoStart from '@/pages/auth/SsoStart';
import SsoCallback from '@/pages/auth/SsoCallback';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                guest: true
            }
        },
        {
            path: '/sso/:slug',
            name: 'SsoStart',
            component: SsoStart
        },
        {
            path: '/sso',
            name: 'SsoCallback',
            component: SsoCallback
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                guest: true
            }
        },
        {
            path: '/registerConfirm',
            name: 'RegisterConfirm',
            component: RegisterConfirm,
            meta: {
                // guest: true
            }
        },
        {
            path: '/validate/:token',
            name: 'RegisterValidate',
            component: RegisterValidate,
            meta: {}
        },
        {
            path: '/twofactor',
            name: 'TwoFactor',
            component: TwoFactor,
            meta: {}
        },
        {
            path: '/twofactorconfig',
            name: 'TwoFactorConfig',
            component: TwoFactorConfig,
            meta: {}
        },
        {
            path: '/resetpassword',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                guest: true
            }
        },
        {
            path: '/resetpasswordconfirm',
            name: 'ResetPasswordConfirm',
            component: ResetPasswordConfirm,
            meta: {
                guest: true
            }
        },
        {
            path: '/reset/:token',
            name: 'ResetPasswordValidate',
            component: ResetPasswordValidate,
            meta: {
                guest: true
            }
        },
        {
            path: '/resetpasswordsuccess',
            name: 'ResetPasswordSuccess',
            component: ResetPasswordSuccess,
            meta: {
                guest: true
            }
        },
        {
            path: '/resetpassword/:email/_',
            name: 'AutoResetPassword',
            component: AutoResetPassword,
            meta: {
                guest: true
            }
        },
        // Access
        {
            path: '/access',
            component: AccessBase,
            children: AccessRoutes
        },
        {
            path: '/access-account/:id',
            component: AccessWithRequest,
            meta: {
                requiresAuth: true
            }
        },
        // Organisation
        {
            path: '/',
            name: 'AppBase',
            component: AppBase,
            children: AppRoutes,
            meta: {
                requiresAuth: true,
                requiresQfactProduct: true,
                context: 'app'
            }
        },
        {
            path: '/reset/email/:token',
            name: 'ResetEmail',
            component: ResetEmail
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout
        },
        // enquete
        {
            path: '/fill/:id',
            name: 'EnqueteFill',
            component: EnqueteFill
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    const overflowContainer = document.getElementById('content-overflow');
    if(overflowContainer && to.path !== from.path) overflowContainer.scrollTo(0, 0);

    const cleanTargetUrl = removeUrlQueryParam(to.path, 'sso');
    // remove sso query param if existant
    if (to.query.sso) router.push(cleanTargetUrl);

    if (to.matched.some(record => record.meta.requiresAuth)) {

        const activeProducts = store.getters.getCurrentOrganisation && store.getters.getCurrentOrganisation.products instanceof Array ? store.getters.getCurrentOrganisation.products.filter(product => product.enabled).map(product => product.slug) : [];
        if (store.getters.getOrganisationType === 'contractor' || !activeProducts.includes('crow_client')) {
            if (to.path.includes('/companies') || to.path.includes('/analysis')) {
                noAccessRedirect('/', next)
            }
        }

        if (store.getters.isAuthenticated) {
            const ability = new Ability();
            ability.set(store.getters.getJwtPayload);

            if (!ability.get().can('view', 'Analysis')) {
                if (to.path.includes('/companies') || to.path.includes('/analysis')) {
                    noAccessRedirect('/', next)
                    return;
                }
            }

            const isContractor = store.getters.getActiveProducts.filter(product => product.slug.includes('contractor')).length > 0;
            const hasQfactPD = store.getters.hasQfactPD;
            const hasPDFull = store.getters.hasPDFullProduct;
            const cannotAccessBeheer = !hasQfactPD || !isContractor || store.getters.getOrganisationRole === 'user-default';
            const isCrowMasterOrganisation = store.getters.getCurrentOrganisation.id === 'crow-master-organisation';

            if (cannotAccessBeheer && (to.path.includes('/styles') || to.path.includes('/templates'))) {
                noAccessRedirect('/settings', next)
                return;
            }

            if (cannotAccessBeheer && (to.path.includes('/manage'))) {
                noAccessRedirect('/settings', next)
                return;
            }

            if (!hasPDFull && (to.path.includes('/manage/formtemplates'))) {
                noAccessRedirect('/settings', next)
                return;
            }

            if ((!isContractor || !hasQfactPD) && to.path.includes('/letter')) {
                const projectId = to.params.id;
                noAccessRedirect(`/projects/${projectId}`, next)
                return;
            }

            if (((!isContractor && !isCrowMasterOrganisation) || 
                (isContractor && (!hasQfactPD && !hasPDFull))) &&
                (to.path.includes('/reports'))) {
                noAccessRedirect('/', next)
                return;
            }

            if (to.matched.some(record => record.meta.requiresQfactProduct)) {
                if (store.getters.hasActiveQfactProducts || store.getters.hasActiveQfactProjects || hasQfactPD) {
                    next();
                    return;
                }

                next('/access');

            } else {
                next();
                return;
            }
        }
        
        if (to.query.sso && !store.getters.isAuthenticated) {
            next(`/sso/${to.query.sso}`);
        } else {
            next('/login');
        }

        localStorage.setItem('targetUrl', cleanTargetUrl);
    } else if (to.matched.some(record => record.meta.guest)) {
        if (!localStorage.getItem('jwt')) {
            next();
            return;
        }
        next('/access');
    } else {
        next();
    }
});

function removeUrlQueryParam(link, query) {
    if(!link.includes('?')) return link

    const url = new URL(link);
    const params = new URLSearchParams(url.search);
    params.delete(query);
    return link.split('?')[0] + params;
}

function noAccessRedirect(to, next) {
    next(to)
    store.commit('notify', {
        type: 'warning',
        message: `U heeft geen toegang tot deze pagina.`
    });
}

router.afterEach(() => {
    window.productFruits?.pageChanged?.();
});

export default router;
