<template>
<div>
    <div class="q-card-medium">
        <q-section paddingTop="40" hPadding="40">
            <h2 id='v-tour-0'>Organisatie aanmelden</h2>
            <p v-if="toggleRegion === `Nederland`" class="pt-m">
                Meld hier je organisatie aan. Als Nederlands bedrijf kun je hieronder zoeken op kvk-nummer, naam of
                adres.
            </p>
            <p v-else class="pt-m">
                Als bedrijf buiten Nederland kun je hieronder de naam en het adres van het bedrijf invullen.
            </p>
        </q-section>
        <q-section paddingTop="24" paddingBottom="24" hPadding="40" >
            <q-toggle  class="toggle " :choices="choicesRegion" v-model="toggleRegion"> </q-toggle>
        </q-section>
        <q-section paddingBottom="24" hPadding="40" v-if="toggleRegion === `Nederland`">
            <q-section    id="v-tour-1">
                <q-input labelPosition="inside"
                    v-if="this.branchChoice == ``"
                    v-model="orgInput"
                    placeholder="Zoek op naam, adres of KvK-nummer"
                    @input="fetchKvk"
                 
                >
                    Naam / adres / KvK-nummer
                </q-input>
                <div v-if="loading" class="loader"></div>

                <div v-if="orgInput.length > 0 && this.branchChoice == ``" class="wrapperDiv">
                    <div
                        v-for="branch in branches"
                        v-bind:value="branch.value"
                        :key="branch.number"
                        @click="clickBranch(branch)"
                        class="branchDiv"
                    >
                        <p class="nameLine">{{ branch.name }}</p>
                        <p class="numberLine">
                            Kvk-nummer: {{ branch.legal.registrationId }}
                        </p>
                        <div class="space-between">
                            <p class="addressLine">
                                {{ branch.address.line1 }}, {{ branch.address.zip }} {{ branch.address.city }}
                            </p>
                            <div>
                                <q-tag v-if="branch.mainExists && branch.mainExists.exists" size="small" variation="info">Aangemeld</q-tag>
                            </div>

                        </div>

                    </div>
                </div>
                <div v-else-if="orgInput.length > 0" class="selectedDiv">
                    <div>
                        <p class="nameLine">{{ branchChoice.name }}</p>
                        <p class="numberLine">
                            Kvk-nummer: {{ branchChoice.legal.registrationId }}
                        </p>
                        
                        <div class="space-between">
                            <p class="addressLine">
                                {{ branchChoice.address.line1 }}, {{ branchChoice.address.zip }}
                                {{ branchChoice.address.city }}
                            </p>
                            <div>
                                <q-tag v-if="branchChoice.mainExists && branchChoice.mainExists.exists" size="small" variation="info">Aangemeld</q-tag>
                            </div>
                            

                        </div>
                    </div>
                    <q-button style="margin-top: 8px;" variation="light" size="xsmall" @click="emptyBranches"
                        >Verander keuze</q-button
                    >
                                            <q-icon class="check-icon" type="check" color='#37b34e' width='20' height='20' />

                </div>
            </q-section>
        </q-section>

        <q-section paddingBottom="24" hPadding="40" v-if="toggleRegion === `Europa`">
            <q-input labelPosition="inside" v-model="europeName" placeholder="Naam organisatie">
                Naam organisatie
            </q-input>
            <q-input labelPosition="inside" v-model="address.line1" placeholder="Straatnaam en nummer">
                Straatnaam en nummer
            </q-input>
            <div class="address-form row between-xs">
                <q-input labelPosition="inside" v-model="address.zip" class="col-xs-6 gutter" placeholder="Postcode">Postcode</q-input>
                <q-input labelPosition="inside" v-model="address.city" class="col-xs-6 gutter" placeholder="Stad">Stad</q-input>
            </div>
        </q-section>

        <q-section class="space-between" vPadding="24" hPadding="40" borderTop>
            <q-button variation="blank" go="-1">
                Terug
            </q-button>
            <q-button @click="handleRegistration" :loading="checkingBranch" v-if="!organisationExists">
                Organisatie aanmelden
            </q-button>
            <q-button variation="primary" @click="requestAccess" v-else>
                Toegang vragen
            </q-button>
        </q-section>
    
</div>
    </div>
    

</template>

<script>
import Notifications from '@/components/Notifications';
import AccessBase from '@/pages/access/AccessBase';
import { REGISTER_ORGANISATION, REQUEST_ORGANISATION_ACCESS } from '@/graphql/mutations';
import { CHECK_LEGAL_ORG, KVK_COMPANIES } from '@/graphql/queries';
import _ from 'lodash';
import { extractError } from '@/assets/js/utils';

export default {
    name: 'RegisterOrg',
    components: {
        Notifications,
        AccessBase
    },

    data() {
        return {
            user: this.$store.getters.getUser,
            orgInput: '',
            nlName: '',
            europeName: '',
            address: {
                line1: '',
                zip: '',
                city: '',
                country: ''
            },
            legal: {
                registrationId: '',
                branchId: ''
            },
            branches: [],
            branchChoice: '',
            motivation: '',
            toggleRegion: 'Nederland',
            choicesRegion: [
                { text: `Nederland`, label: `Nederland` },
                { text: `Europa`, label: `Europa` }
            ],
            kvkLoading: false,
            checkingBranch: false,
            organisationExists: false,
            requestOrganisation: '',
           
        };
    },

    

    methods: {
        requestAccess() {
            let v = this;

            this.$apollo
                .mutate({
                    mutation: REQUEST_ORGANISATION_ACCESS,
                    variables: {
                        id: this.user.id,
                        organisationId: this.requestOrganisation
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`
                        }
                    }
                })
                .then(response => {
                    this.$router.push('/access');
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        handleRegistration() {
            if (this.toggleRegion === 'Europa') {
                this.$apollo
                    .mutate({
                        mutation: REGISTER_ORGANISATION,
                        variables: {
                            name: this.europeName,
                            address: this.address
                        }
                    })
                    .then(response => {
                        this.$router.push('/access');
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            } else if (this.toggleRegion === 'Nederland') {
                if (!this.branchChoice)
                    return this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Kies eerst een organisatie'
                    });

                this.$apollo
                    .mutate({
                        mutation: REGISTER_ORGANISATION,
                        variables: {
                            name: this.branchChoice.name,
                            address: this.branchChoice.address,
                            legal: this.branchChoice.legal
                        },
                    })
                    .then(response => {
                        this.$router.push('/access');
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            }
        },

        clickBranch(branch) {
            let vm = this;
            this.branchChoice = branch;
            this.checkingBranch = true;
            this.organisationExists = false;

            this.checkKvkExists(this.branchChoice.legal.registrationId)
                .then(result => {
                    vm.checkingBranch = false;
                    if (result.exists) {
                            vm.organisationExists = true;
                            vm.requestOrganisation = result.id;

                            this.$store.commit('notify', {
                                type: 'info',
                                message: `${this.branchChoice.name} is al bekend bij ons. U kunt toegang vragen tot deze organisatie.`
                            });
                        }
                })
                .catch(err => {
                    vm.checkingBranch = false;
                    extractError(err, vm.$store);
                });
        },
        async checkKvkExists(kvk) {
            return this.$apollo
                .query({
                    query: CHECK_LEGAL_ORG,
                    variables: {
                        registrationId: kvk,
                        type: 'main'
                    },
                })
                .then(response => response.data.org_legalExists)
                .catch(err => this.$store.commit('notify', extractError(err)));
        },
        emptyBranches() {
            this.branchChoice = '';
            this.orgInput = '';
            this.nlName = '';
        },

        debounceKvkCall: _.debounce(function() {
            this.kvkCall();
        }, 500),
        kvkCall() {

            const vm = this;

            this.$apollo
                .query({
                    query: KVK_COMPANIES,
                    variables: {
                        term: this.orgInput,
                        includeBranches: false
                    },
                })
                .then(response => {
                    console.log(response);
                    const items = response.data.kvk_companies;
                    const mainBranches = items.filter(item => item.isMainBranch == true);
                    vm.branches = mainBranches.map(item => {
                        return {
                            value: item.branchNumber,
                            name: item.tradeNames.businessName,
                            address: {
                                line1: `${item.addresses[0].street} ${item.addresses[0].houseNumber}`,
                                zip: ` ${item.addresses[0].postalCode}`,
                                city: ` ${item.addresses[0].city}`,
                                country: `${item.addresses[0].country}`
                            },
                            legal: {
                                registrationId: item.kvkNumber,
                                branchId: item.branchNumber,
                                country: item.addresses[0].country,
                            },
                            number: item.branchNumber,
                            mainExists: item.mainExists
                        };
                    });

                    vm.kvkLoading = false;
                })
                .catch(err => {
                    vm.kvkLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },

        fetchKvk() {
            if (this.orgInput != '') {
                this.kvkLoading = true;
            }
            this.debounceKvkCall();
        }
    },

    computed: {
        loading() {
            return this.kvkLoading && this.orgInput;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';

.infotext {
    margin-top: 8px;
    font-size: 0.6rem;
    line-height: 0.8rem;
    width: 80%;
    color: #6a737c;
}

.wrapperDiv {
    max-height: 250px;
    margin: 5px 0;
    overflow: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
}
.dropdownDiv div:first-child {
    border-radius: 4px 4px 0 0;
}

.dropdownDiv div:last-child {
    border: none;
}

.branchDiv {
    border-bottom: 1px solid $color-grey-3;
    padding: 15px;
    padding-left: 0;
    cursor: pointer;

    &:last-child {
        border: none;
    }
}
.nameLine {
    font-weight: 500;
    font-size: 20px;
}

.numberLine {
    font-size: 12px;
    color: $color-grey-5;
}

// .wrapperSection{
//     height:300px;
// }

.selectedDiv {
    padding: 10px;
    border: 1px solid $color-grey-3;
    border-radius: 5px;
    position: relative;

    & .check-icon {
        position: absolute;
        bottom: 7px;
        right: 12px;
    }
}

.loader {
    $loader-color: $color-primary;
    $loader-size: 8px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1.2s;
    @import '../../components/qds/assets/loaders/loaders.scss';
    @include loader12;
    margin: auto;
    margin-top: 12px;
}
</style>
