<template>
    <div class="label-switch" :style="`--min-width: calc(${optionWidth} * ${options.length})`">
        <div 
            class="options" 
            :style="`--bar-width: ${100 / options.length}%; --bar-left: ${computedBarLeft}; --bar-right: ${computedBarRight}`" 
            :class="navigationDirection">
            <div v-for="option in options" :key="option.label" class="option" :class="{ active: activeOption == option.value }" @click="handleSelectOption(option)">
                <span>{{ option.label }}</span>
                <div v-if="option.tagLabel" class="tag" :style="`--background-color: ${option.tagColor}`">{{ option.tagLabel }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'q-label-switch',
    props: {
        /*  Options is an array with the following properties:
        *   label: String
        *   value: String
        *   tagLabel: String
        *   tagColor: String
        */
        options: {
            type: Array,
            required: true
        },
        /*
        *   The available width all the options will get
        */
        optionWidth: {
            type: String,
            default: '100px'
        },
        /*
        *   v-model (option.value)
        */
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            activeOption: this.value,
            navigationDirection: 'forwards' // can be 'forwards' or 'backwards'
        }
    },
    methods: {
        handleSelectOption(option) {
            const oldIndex = this.options.findIndex(_option => _option.value === this.activeOption);
            const newIndex = this.options.findIndex(_option => _option.value === option.value);
            this.navigationDirection = oldIndex < newIndex ? 'forwards' : 'backwards';
            this.activeOption = option.value;
            this.$emit('input', option.value);
        }
    },
    computed: {
        computedBarLeft: function() {
            const length = this.options.length;
            const step = 100 / length;
            const index = this.options.findIndex(option => option.value === this.activeOption);

            return `${step * index}%`
        },
        computedBarRight: function() {
            const length = this.options.length;
            const step = 100 / length;
            const index = this.options.findIndex(option => option.value === this.activeOption);

            return `${(length - index - 1) * step}%`
        }
    }
}
</script>

<style lang="scss" scoped>

.label-switch {
    background-color: #D9F1F3;
    border-radius: 4px;
    padding: 0px 8px;
    width: calc(var(--min-width) + 16px);
    font-family: 'Gotham';

    .options {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.forwards:before{
            transition: 
                inset-inline-start 150ms ease 100ms,
                inset-inline-end 250ms ease;
        }
        &.backwards:before {
            transition: 
                inset-inline-start 250ms ease,
                inset-inline-end 150ms ease 100ms;
        }

        &:before {
            content: "";
            position: absolute;
            inset-block: 4px;
            background-color: white;
            inset-inline-start: calc(var(--bar-left) - 1px);
            inset-inline-end: calc(var(--bar-right) - 1px);
            border: 1px solid #00A1AE80;
            border-radius: 3px;
            box-shadow: 0 2px 6px -5px #000000;
        }

        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #00A1AE;
            cursor: pointer;
            padding: 11px 0px;
            width: var(--bar-width);
            user-select: none;
            z-index: 1;
    
            &.active {
                font-weight: 500;
            }

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
            }

            .tag {
                background-color: var(--background-color, '#FD7E14');
                color: white;
                font-weight: 500;
                font-size: 12px;
                border-radius: 12px;
                padding: 2px 5px;
            }
        }
    }

}
</style>