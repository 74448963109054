<template>
    <div class="full-width min-height">
        <div class="justify-center">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)"
                    >Profiel</arrow-button
                >
            </div>
        </div>
        <div class="justify-center">
            <div class="settings-intro">
                <h1>Organisatiebeheer</h1>
                <p>
                    Bekijk en beheer de toegang tot organisaties.<br />
                    Vraag toegang aan en bekijk de status van je aanvraag.
                </p>
            </div>
        </div>

        <div class="justify-center">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>Toegang</h3>

                    <p v-if="activeOrganisations.length > 0">
                        Hieronder vind je de organisaties waar je toegang tot hebt.
                    </p>

                    <p v-else>
                        Op het moment heb je nog geen toegang tot een organisatie. Druk op onderstaande knop om toegang
                        te vragen tot een bestaande organisatie, of meld je organisatie aan.
                        <br />
                        <q-button variation="blank" class="registerButton" @click="goToRegisterOrg" :disabled="user.status!='active' ">
                            Organisatie aanmelden
                        </q-button>
                    </p>
                </div>
                <div class="org-container">
                    <div v-for="organisation in activeOrganisations" :key="organisation.id" class="org">
                        <div class="head end">
                            <q-icon type="lock-open" class="icon-padding" color="green" />
                        </div>
                        <div class="justify-center mb-s">
                            <q-avatar size="large" :img="organisation.logo" class="image"></q-avatar>
                        </div>
                        <p class="org-name">{{ organisation.name }}</p>
                        <p class="org-address">{{ getAddress(organisation.address) }}</p>
                        <div class="org-label">
                            <q-tag variation="default" size="small" class="org-container-label"
                                    >{{ myRole(organisation)}}</q-tag
                                >
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

        <div class="justify-center" v-if="potentialOrganisations instanceof Array && potentialOrganisations.length > 0">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>Toegang vragen</h3>
                    <p>
                        Op basis van je account kun je toegang krijgen tot de onderstaande organisaties. Jouw
                        organisatiebeheerder kan jou vervolgens toegang verschaffen.
                    </p>
                </div>
                <div class="org-container">
                    <div v-for="organisation in potentialOrganisations" :key="organisation.id" class="org">
                        <div class="top">
                            <div class="head end">
                                <q-icon type="lock-closed" class="icon-padding" color="red" />
                            </div>
                            <div class="justify-center mb-s">
                                <q-avatar size="large" :img="organisation.logo" class="image"></q-avatar>
                            </div>
                            <p class="org-name">{{ organisation.name }}</p>
                            <p class="org-address">{{ getAddress(organisation.address) }}</p>
                        </div>
                        <div class="action">
                            <q-icon
                                v-if="confirmed.includes(organisation.id)"
                                type="check"
                                class="icon-padding"
                                color="green"
                            />
                            <q-button
                                :variation="
                                    confirm === organisation.id || confirmed.includes(organisation.id)
                                        ? 'blank'
                                        : 'ghost'
                                "
                                size="small"
                                @click="access(organisation.id)"
                            >
                                {{
                                    confirmed.includes(organisation.id)
                                        ? 'Aangevraagd'
                                        : confirm === organisation.id
                                        ? 'Klik nogmaals om te bevestiging'
                                        : 'Toegang vragen'
                                }}
                            </q-button>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

        <div class="justify-center" v-if="newOrganisations instanceof Array && newOrganisations.length > 0">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>In behandeling</h3>
                    <p>
                        Hieronder vind je de door jou aangemelde organisaties die in afwachting zijn van goedkeuring, en
                        de organisaties waarvoor je toegang hebt gevraagd. De beheerder van de organisatie kan jou
                        toegang geven. <br />
                    </p>
                </div>
                <div class="org-container">
                    <div v-for="organisation in newOrganisations" :key="organisation.id" class="org">
                        <div class="top">
                            <div class="head end empty"></div>
                            <div class="justify-center mb-s">
                                <q-avatar size="large" :img="organisation.logo" class="image"></q-avatar>
                            </div>
                            <p class="org-name">{{ organisation.name }}</p>
                            <p class="org-address">{{ getAddress(organisation.address) }}</p>
                            <div class="org-label">
                                <q-tag v-if="organisation.status === 'new'" variation="info" size="small" class="org-container-label"
                                    >in afwachting van goedkeuring</q-tag
                                >
                            </div>
                        </div>
                        <div class="action" v-if="organisation.status !== 'new'">
                            <q-button
                                :variation="cancelConfirm === organisation.id ? 'blank' : 'ghost'"
                                size="small"
                                @click="cancelOrganisation(organisation.id)"
                            >
                                {{
                                    cancelConfirm === organisation.id
                                        ? 'Klik nogmaals om te bevestigen'
                                        : 'Verzoek annuleren'
                                }}
                            </q-button>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

        <div class="justify-center" v-if="invitedOrganisations instanceof Array && invitedOrganisations.length > 0">
            <q-section class="org-block" vPadding="37" borderTop>
                <div class="intro">
                    <h3>In behandeling</h3>
                    <p>
                        Hieronder vind je de de organisatie die jou hebben uitgenodigd. <br />
                    </p>
                </div>
                <div class="org-container">
                    <div v-for="organisation in invitedOrganisations" :key="organisation.id" class="org">
                        <div class="top">
                            <div class="head end empty"></div>
                            <div class="justify-center mb-s">
                                <q-avatar size="large" :img="organisation.logo" class="image"></q-avatar>
                            </div>
                            <p class="org-name">{{ organisation.name }}</p>
                            <p class="org-address">{{ getAddress(organisation.address) }}</p>
                            <div class="org-label"></div>
                        </div>
                        <div class="action">
                            <q-button
                                :variation="acceptInviteButton === organisation.id ? 'blank' : 'ghost'"
                                size="small"
                                @click="acceptInvite(organisation.id)">
                                {{
                                    acceptInviteButton === organisation.id
                                        ? 'Klik nogmaals om te bevestigen'
                                        : 'Uitnodiging accepteren'
                                }}
                            </q-button>
                            <q-button
                                :variation="cancelInviteButton === organisation.id ? 'blank' : 'ghost'"
                                size="small"
                                @click="cancelInvite(organisation.id)">
                                {{
                                    cancelInviteButton === organisation.id
                                        ? 'Klik nogmaals om te bevestigen'
                                        : 'Uitnodiging afwijzen'
                                }}
                            </q-button>
                        </div>
                    </div>
                </div>
            </q-section>
        </div>

    </div>
</template>

<script>
import {
    USER_POTENTIAL_ORGANISATIONS,
    REQUEST_ORGANISATION_ACCESS,
    CANCEL_ORGANISATION_ACCESS,
    ACCEPT_ORGANISATION_INVITE,
    DECLINE_ORGANISATION_INVITE
} from '@/graphql/mutations';
import { ORG_MINE, GET_USER_ORGANISATIONS } from '@/graphql/queries';

import { getAddress, extractError } from '@/assets/js/utils.js';
import ArrowButton from '@/components/ArrowButton';

export default {
    name: 'OrganisationsSettings',
    components: {
        ArrowButton
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            organisations: [],
            potentialOrganisations: [],
            requestedOrganisations: [],
            activeOrganisations: [],
            newOrganisations: [],
            invitedOrganisations: [],
            confirm: '',
            confirmed: [],
            cancelConfirm: '',
            acceptInviteButton: '',
            cancelInviteButton: '',
            timeout: null
        };
    },
    methods: {
        acceptInvite(id) {

            if (this.acceptInviteButton === id)
                this.$apollo
                    .mutate({
                        mutation: ACCEPT_ORGANISATION_INVITE,
                        variables: {
                            id: this.user.id,
                            organisationId: id
                        }
                    })
                    .then(response => {
                        if (response.data.user_cancelOrganisationRequest) {
                            this.getInvitedOrganisations();
                        }
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            else {
                let v = this;

                this.acceptInviteButton = id;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    v.acceptInviteButton = '';
                }, 3000);
            }
        },
        cancelInvite(id) {

            if (this.cancelInviteButton === id)
                this.$apollo
                    .mutate({
                        mutation: DECLINE_ORGANISATION_INVITE,
                        variables: {
                            id: this.user.id,
                            organisationId: id
                        }
                    })
                    .then(response => {
                        if (response.data.user_cancelOrganisationRequest) {
                            this.getInvitedOrganisations();
                        }
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            else {
                let v = this;

                this.cancelInviteButton = id;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    v.cancelInviteButton = '';
                }, 3000);
            }
        },
        myRole(org) {
            const myUser = org.users.find(user => user.userId === this.$store.getters.getUser.id)
            return myUser.role.name;

        },
        cancelOrganisation(id) {
            let v = this;

            this.confirm = '';

            if (this.cancelConfirm === id)
                this.$apollo
                    .mutate({
                        mutation: CANCEL_ORGANISATION_ACCESS,
                        variables: {
                            id: this.user.id,
                            organisationId: id
                        },
                        context: {
                            headers: {
                                Authorization: `Bearer ${this.$store.getters.getJwt}`
                            }
                        }
                    })
                    .then(response => {
                        if (response.data.user_cancelOrganisationRequest) {
                            v.$apollo.queries.userOrganisations.refresh();
                            // Wait for the subscription on user cancel organisation request to update organisation model for refetch
                            setTimeout(() => {
                                v.getPotentialOrganisations();
                            }, 100);

                            if (v.confirmed.includes(id))
                                v.confirmed.forEach((id_, key) => {
                                    if (id == id_) this.confirmed.splice(key, 1);
                                }); //Remove from confirmed array
                        }
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            else {
                let v = this;

                this.cancelConfirm = id;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    v.cancelConfirm = '';
                    v.confirm = '';
                }, 3000);
            }
        },

        goToRegisterOrg() {
            window.location.href = `${process.env.EMVI_APP_URL}/opdrachtnemers/create`
        },

        access(id) {
            let v = this;

            if (this.confirmed.includes(id)) return;

            if (this.confirm === id) {
                this.$apollo
                    .mutate({
                        mutation: REQUEST_ORGANISATION_ACCESS,
                        variables: {
                            id: this.user.id,
                            organisationId: id
                        }
                    })
                    .then(response => {
                        if (response.data.user_requestOrganisationAccess) {
                            this.confirmed.push(id);
                            v.getUserOrganisations();
                        }
                    })
                    .catch(err => {
                        this.$store.commit('notify', extractError(err));
                    });
            } else {
                let v = this;

                this.confirm = id;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    v.confirm = '';
                    v.cancelConfirm = '';
                }, 3000);
            }
        },
        getPotentialOrganisations() {
            const extension = this.getEmailExtension();

            this.$apollo
                .query({
                    query: USER_POTENTIAL_ORGANISATIONS,
                    variables: {
                        where: {
                            AND: [{ whitelistedEmails__contains: extension }, { users__none: { userId: this.user.id } }]
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(response => {
                    this.potentialOrganisations = response.data.organisations;
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        getInvitedOrganisations() {
            this.$apollo
                .query({
                    query: GET_USER_ORGANISATIONS,
                    variables: { id: this.user.id },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.invitedOrganisations = result.data.user.organisations.map(organisation => organisation.organisation)
                })
                .catch(error => {
                    
                })
        },
        getAddress(address) {
            return getAddress(address);
        },
        getEmailExtension() {
            return this.user.email.split('@')[1];
        }
    },
    mounted() {
        this.getPotentialOrganisations();
        this.getInvitedOrganisations();
    },
    apollo: {
        userOrganisations: {
            query: ORG_MINE,
            fetchPolicy: 'no-cache',
            update: (data) => data.org_mine,
            result(res) {
                const vm = this;
                if (res.data.org_mine) {
                    const orgs = res.data.org_mine || [];
                    const activeOrgs = []
                    const newOrgs = []
                    orgs.forEach(org => {
                        const user = org.users.find(user => user.userId === vm.$store.getters.getUser.id)
                        if (org.status == 'active' && user.status === 'active') {
                            activeOrgs.push(org);
                        } else if (user.status === 'requested' || (user.status === 'active' && org.status === 'new')) {
                            newOrgs.push(org);
                        }
                        vm.activeOrganisations = activeOrgs;
                        vm.newOrganisations = newOrgs;                        
                    })
                }
            },
        },
    }
};

const wait = async ms => await new Promise(r => setTimeout(r, ms));
</script>

<style lang="scss" scoped>
.action {
    display: flex;
    justify-content: center;
    align-items: center;
}

.action > .icon {
    margin-top: 7px;
}

.empty {
    height: 18px;
}

.end {
    display: flex;
    justify-content: flex-end;
}

.head {
    padding: 9px 14px 14px 14px;
}

.org {
    position: relative;
    width: 220px;
    height: 259px;
    text-align: center;

    background: #ffffff;
    /* color / gray 3 */

    border: 1px solid #dee2e6;
    box-sizing: border-box;
    /* shadow / button hover */

    box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.15);
    border-radius: 4px;
    margin: 16px 32px 16px 0;

    & .org-label {
        display: flex;
    justify-content: center;
    }
}

.org > .top {
    height: 80%;
}

.org > .image {
}

.org-name {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 0 10px;
}

.org-address {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding: 0 10px;
}

.org-container {
    display: flex;
    flex-wrap: wrap;
}

.org-block > .intro > h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
}

.org-block > .intro > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 14px;
    margin-bottom: 21px;
}

.intro {
    text-align: left;
}

.org-block {
    width: 800px;
}

.full-width {
    width: 100%;
}

.min-height {
    min-height: 100vh;
}

.settings-intro {
    width: 800px;
    text-align: left;

    h1 {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 31px;
        line-height: 33px;
    }

    p {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-top: 11px;
        width: 50%;
        margin-bottom: 24px;
    }
}
.settings-back {
    width: 800px;
    text-align: left;
    margin-top: 35px;
    margin-bottom: 5px;
}
.org-container-label {
    position: absolute;
    bottom: 18px;
    // left: 18px;
}

.registerButton {
    position: absolute;
    bottom: -10px;
    left: 295px;

    &:disabled{
        pointer-events: none;
    }
}
</style>
