<template>
    <div :style="isLastColumn ? 'position: relative;' : ''">
        <div v-if="active" :class="['q-card-small', multiLineHeader ? 'filter-multiline' : 'filter', { isLastColumn }]" id="filter-panel">
            <!-- CHECKBOX -->
            <div v-if="variation === 'checkbox'">
                <q-section :class="isLastColumn ? 'flex-justify-start' : 'flex-justify-end'">
                    <span v-if="!disableSorting" class="sortToggle" @click="sortToggle">Abc<q-icon class="arrowIcon" :style="sorting === 'ASC' ? 'transform: rotate(0deg)' : 'transform: rotate(180deg)'" width="8px" height="8px" type="ArrowDown"></q-icon></span>
                </q-section>
                
                <div v-if="showSearchInput" class="searchBar" :style="!disableSorting ? 'margin-top: 0;' : 'margin-top: 16px;'">
                    <q-icon class="searchIcon" width="16px" height="16px" type="search"></q-icon>
                    <input v-model="search" id="filter-input" class="searchInput" :placeholder="`Zoek een ${filterName.toLowerCase()}`" @input="checkboxSearchChanged" />
                </div>
                <q-section class="space-between">
                    <q-button variation="blank" size="small" @click="batchToggle(true)">Alles selecteren</q-button>
                    <q-button variation="blank" size="small" @click="batchToggle(false)">Selectie wissen</q-button>
                </q-section>

                <transition-group name="options-list" :id="`${componentId}-options`" class="resultWrap">
                    <q-checkbox
                        v-model="filterData[option.value]"
                        v-for="option in sortedOptions"
                        :key="option.value"
                        :value="option.value"
                        :class="`${componentId}-option option`"
                        ref="options"
                        @input="toggleFilter($event, option.value)">
                        {{  option.label }}
                    </q-checkbox>
                </transition-group>
            </div>

            <!-- SCORE -->
            <div v-else-if="variation === 'score'">
                <q-section :class="isLastColumn ? 'flex-justify-start' : 'flex-justify-end'">
                    <span v-if="!disableSorting" class="sortToggle" @click="sortToggle">123<q-icon class="arrowIcon" :style="sorting === 'ASC' ? 'transform: rotate(0deg)' : 'transform: rotate(180deg)'" width="8px" height="8px" type="ArrowDown"></q-icon></span>
                </q-section>
                
                <q-section class="space-between">
                    <q-button variation="blank" size="small" @click="resetScoreFilter">Alles selecteren</q-button>
                </q-section>

                <div class="resultWrap">
                    <q-slider :value="valueSlider" :min=0 :max=10 :steps=1 label="Score" @input="filterScore" range style="padding: 0 10px 0 0"></q-slider>
                </div>
            </div>

            <!-- NUMBER -->
            <div v-else-if="variation === 'number'">
                <q-section :class="isLastColumn ? 'flex-justify-start' : 'flex-justify-end'">
                    <span v-if="!disableSorting" class="sortToggle" @click="sortToggle">123<q-icon class="arrowIcon" :style="sorting === 'ASC' ? 'transform: rotate(0deg)' : 'transform: rotate(180deg)'" width="8px" height="8px" type="ArrowDown"></q-icon></span>
                </q-section>
                
                <q-section class="space-between">
                    <q-button variation="blank" size="small" @click="resetScoreFilter">Alles selecteren</q-button>
                </q-section>

                <div class="resultWrap">
                    <q-slider :value="valueSlider" :min="sliderMin" :max="sliderMax" :steps=1 label="Aantal" @input="filterScore" range style="padding: 0 10px 0 0"></q-slider>
                </div>
            </div>

            <!-- DATEPICKER -->
            <div v-if="variation === 'datePicker'">
                <q-section :class="isLastColumn ? 'flex-justify-start' : 'flex-justify-end'" style="margin-bottom:10px">
                    <span v-if="!disableSorting" class="sortToggle" @click="sortToggle">123<q-icon class="arrowIcon" :style="sorting === 'ASC' ? 'transform: rotate(0deg)' : 'transform: rotate(180deg)'" width="8px" height="8px" type="ArrowDown"></q-icon></span>
                </q-section>

                <div class="resultWrap">
                    <el-date-picker
                        class="datepicker"
                        v-model="date"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Start datum"
                        end-placeholder="Eind datum"
                        format="dd-MM-yyyy"
                        placeholder="Kies een datum"
                        @input="saveDate">
                    </el-date-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export default {
    name: 'q-filter',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         * value: String!,
         * label: String!,
         * selected: Boolean,
         */
        options: {},
        variation: {
            type: String,
            required: true
        },
        filterName: {
            type: String,
            required: true
        },
        /**
         * Whether to show the filter or not.
         */
        active: {
            type: Boolean
        },
        /**
         * Field on which is being filtered
         */
        field: {
            type: String
        },
        /**
         * Whether to render the search input or not
         */
        showSearchInput: {
            type: Boolean,
            default: true
        },
        /**
         * Whether sorting must be disabled
         */
        disableSorting: {
            type: Boolean,
            default: false
        },
        /**
         * Wether this columns is the most right column.
         */
        isLastColumn: {
            type: Boolean
        },
        /**
         * Fits three lines in table header.
         */
        multiLineHeader: {
            type: Boolean, 
            default: false,
        },
        /**
         * Optional default sorting for column
         */
        defaultSort: {
            type: String,
            default: 'DESC'
        },
         /* The min value of the range slider */
        sliderMin: {
            type: Number, 
            default: 0,
        },
        /**
         * The max value of the range slider
         */
        sliderMax: {
            type: Number, 
            default: 10,
        }
    },

    data() {
        return {
            filterData: {},
            valueSlider: [this.sliderMin, this.sliderMax],
            sorting: this.defaultSort,
            search: '',
            date: null,
            componentId: uuidv4(),
            sortedOptions: [],
            initialized: false
        };
    },
    methods: {
        async checkboxSearchChanged(searchQuery) {
            this.$emit('checkboxSearchChanged', this.getFilterData());
        },
        handleClick() {
            this.$emit('close');
        },
        sortToggle(){
           this.sorting = this.sorting === 'ASC' ? 'DESC' : 'ASC'

           this.$emit('change', this.getFilterData())
        },
        batchToggle(boolean) {
            if (this.options.length === 0) return;

            this.sortedOptions = this.options.map(option => {
                option.selected = boolean;
                this.filterData[option.value] = boolean;
                return option
            });

            this.$emit('change', this.getFilterData());
        },
        filterScore(value) {
            if(_.isEqual(this.valueSlider, value)) return

            this.valueSlider = value;
            this.$emit('change', {
                sorting: this.sorting,
                filter: value,
                search: this.search
            })
        },
        resetScoreFilter() {
            this.filterScore([]);
            this.valueSlider = [this.sliderMin, this.sliderMax];
        },
        toggleFilter(value, option) {
            this.filterData[option] = value;

            this.$emit('change', this.getFilterData());
        },
        saveDate() {
            const filter = {
                sorting: this.sorting,
                filter: this.date ? {
                    from: new Date(this.date[0]).getTime(),
                    to: new Date(this.date[1]).getTime() + 86400000
                } : {}
            }

            this.$emit('change', filter)
        },
        getFilterData() {
            let filteredData = [];

            for (const key in this.filterData) {
                if (this.filterData[key]) {
                    filteredData.push(key);
                }
            }
            return {
                sorting: this.sorting,
                filter: filteredData,
                search: this.search
            };
        },
        update() {
            this.key++;
        },
        sortOptions() {
            if(this.variation !== 'checkbox') return
            if(!this.options) this.sortedOptions = this.options;
            this.sortedOptions = this.options.sort((a, b) => {
                if(this.filterData[a.value]) return -1
                if(this.filterData[b.value]) return 1
                return 0
            })
        },
        updateFilterList() {
            const optionsList = document.getElementById(`${this.componentId}-options`);
            if(!optionsList) return

            const listHeight = optionsList.getBoundingClientRect().height;
            const scrollTop = optionsList.scrollTop || 0;

            for(let i = 0; i < optionsList.children.length; i++) {
                const option = optionsList.children[i];
                const height = option.getBoundingClientRect().height;
                const effectOffset = Math.ceil(height * 0.6);

                if(i === optionsList.children.length - 1 && listHeight >= 296) {
                    option.style.marginBottom = '12px';
                }

                if((option.offsetTop + effectOffset) > (scrollTop + listHeight)) {
                    // option is in bottom overflow of the list
                    option.style.opacity = 0.5;
                    option.style.transform = 'translateY(12px)';
                }
                else if((option.offsetTop + height - effectOffset) < scrollTop) {
                    // option is in top overflow of the list
                    option.style.opacity = 0.5;
                    option.style.transform = 'translateY(-12px)';
                }
                else {
                    option.style.opacity = 1;
                    option.style.transform = 'translateY(0px)';
                }
            }
        },
        updateSelectedOptions() {
            const selectedFilters = this.options.filter(filter => filter.selected);
            if(selectedFilters.length == 0) return

            for(let i = 0; i < selectedFilters.length; i++) {
                const key = selectedFilters[i].value;
                this.filterData[key] = selectedFilters[i].selected;
            };
            if(!this.initialized) {
                this.initialized = true;
            }
        }
    },
    computed: {
        getStyle: function(){
            if (this.isLastColumn)
                return `transform:translate(-190px, 0);z-index:2`;
        }
    },
    mounted() {
        if(!this.options) return

        
        if(this.variation === 'datePicker') {
            this.date = this.options;
        }
        else this.updateSelectedOptions();
    },
    watch: {
        active: async function() {
            this.sortOptions();
            await new Promise(r => setTimeout(r, 1)) // wait for list to render

            const input = document.getElementById('filter-input');
            if(this.active && input) input?.focus();
        
            const optionsList = document.getElementById(`${this.componentId}-options`);
            if(!optionsList) return

            const listHeight = optionsList.getBoundingClientRect().height;
            if(listHeight < 296) return

            if(this.active) 
                optionsList.addEventListener('scroll', this.updateFilterList)
            else optionsList.removeEventListener('scroll', this.updateFilterList)
        },
        options: async function() {
            if(this.variation === 'datePicker') return

            if(this.options.length !== this.sortedOptions.length) this.sortedOptions = [...this.options];
            this.sortedOptions = this.sortedOptions.filter(option => !option.hidden);
            this.updateSelectedOptions();

            await new Promise(r => setTimeout(r, 200));
            this.updateFilterList();
        },
        sliderMin: function() {
            this.valueSlider = [this.sliderMin, this.sliderMax];
        },
        sliderMax: function() {
            this.valueSlider = [this.sliderMin, this.sliderMax];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/fonts/fonts.css';

.datepicker {
    width: 265px!important;
}

.flex-justify-start {
    display: flex;
    justify-content: flex-start;
}

.flex-justify-end {
    display: flex;
    justify-content: flex-end;
}

.filter {
    position: absolute;
    margin: -17px;
    margin-top: -27px;
    padding: 20px 16px 16px 16px;
    overflow: hidden;
    left: 0;
    max-width: 300px;
    max-height: 400px;
    transition: .4s ease;
    -webkit-transition: .4s ease;
    cursor: default;
    z-index: 10;

    animation: animateFilter .2s ease forwards;
    -webkit-animation: animateFilter .2s ease forwards;
    -moz-animation: animateFilter .2s ease forwards;

    &.isLastColumn {
        left: unset;
        right: 0px;
    }
}

.filter-multiline {
    position: absolute;
    margin: -17px;
    margin-top: -40px;
    padding: 30px 16px 16px 16px;
    overflow: hidden;
    left: 0;
    max-width: 300px;
    max-height: 400px;
    transition: .4s ease;
    -webkit-transition: .4s ease;
    cursor: default;
    z-index: 10;

    animation: animateFilter .2s ease forwards;
    -webkit-animation: animateFilter .2s ease forwards;
    -moz-animation: animateFilter .2s ease forwards;

    &.isLastColumn {
        left: unset;
        right: 0px;
    }
}

.option {
    padding: 10px;
    padding-left: 0;
    background-color: white;
    transition: .1s ease-in-out;
    -webkit-transition: .1s ease-in-out;

    &:hover {
        padding-left: 4px;
    }
}

.sortToggle {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    transition: .2s ease;
    -webkit-transition: .2s ease;

    .arrowIcon {
        margin-left: 4px;
        transition: transform .2s ease;
        -webkit-transition: transform .2s ease;
    }
}

.sortToggle:hover {
    color: $color-primary;
}

.searchBar {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    margin-inline: -17px;
    border-bottom: 1px solid #d4d8dd;
    color: #c2c6ca;

    .searchIcon {
        margin-right: 4px;
    }
    .searchInput {
        border: none;
        font-size: 12px;
        flex-grow: 1;
        width: 100%;
    }
    .searchInput:focus {
        outline: none;
    }
    .searchInput::placeholder {
        color: #c2c6ca;
    }
    .searchInput::-ms-input-placeholder { /* Microsoft Edge */
        color: #c2c6ca;
    }
}

.resultWrap {
    display: block;
    position: relative;
    max-height: 296px;
    overflow-y: auto;
}

.options-list-enter-active, .options-list-leave-active {
    transition: .1s ease;
    -webkit-transition: .1s ease;
}
.options-list-enter, .options-list-leave-to{
    opacity: 0;
    margin-top: -36px;
    translate: 0 100%;
}
.options-list-move {
    transition: none;
    -webkit-transition: none;
}

@keyframes animateFilter {
    0% {
        opacity: 0;
        max-height: 80px;
    } 50% {
        opacity: 1;
    }100% {
        max-height: 400px;
    }
}
@-webkit-keyframes animateFilter {
    0% {
        opacity: 0;
        max-height: 80px;
    } 50% {
        opacity: 1;
    }100% {
        max-height: 400px;
    }
}
@-moz-keyframes animateFilter{
    0% {
        opacity: 0;
        max-height: 80px;
    } 50% {
        opacity: 1;
    }100% {
        max-height: 400px;
    }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #afafaf; 
  border-radius: 10px;

  &:hover {
    background: rgb(130, 130, 130);
  }
}

</style>
