<template>
  <div class="q-avatar-group">
      <div class="wrapper">
            <q-tooltip v-for="(item, key) in shownImages" :key="key" position="top" :disabled="!item.tooltip" class="tooltip">
                <template #tooltip>
                    <p>{{item.tooltip}}</p>
                </template>
                <img v-if="item.image"
                     class="img"
                     :class="[{ last: key === shownImages.length-1 }, color]"
                     :src="item.image" 
                     :style="'z-index:'+(9-key)" 
                     alt=""
                     @click="click(item)">
                <div 
                    v-else 
                    class="img fallback" 
                    :style="'z-index:'+(9-key)"
                    :class="[{ last: key === shownImages.length-1 }, color]">
                    {{item.fallback}}
                </div>
            </q-tooltip>
            <q-tooltip v-if="max < images.length && max !== images.length-1">
                <template #tooltip>
                    <p v-for="image, index in remainingImages" :key="index">{{image.tooltip}}</p>
                </template>
                <div class="more img" :class="color">
                    <span class="label">+{{images.length - max}}</span>
                </div>
            </q-tooltip>
      </div>
  </div>
</template>

<script>

export default {
    name: 'q-avatar-group',
    components: {

    },
    props: {
        /**
         * Array of objects with attributes:
         * - image: url to image
         * - tooltip: text shown on hover
         *  if not given it won't show any tooltip
         * - fallback: text shown if no image was given
         */
        images: {
            type: Array,
            required: true
        },
        max: {
            type: Number,
            default: 9999
        },
        color: {
            type: String,
            default: "grey",
            validator: (value) => {
                return value.match(/(grey|orange|green|blue|lightgrey)/);
            },
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        click(item) {
            this.$emit('click', item);
        }
    },
    computed: {
        shownImages: function() {
            let v = this;

            return this.images.filter((image, key) => key < v.max || this.images.length-1 === v.max)
        },
        remainingImages: function() {
            return this.images.filter((image, index) => index >= this.max)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.fallback {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-grey-3;
    color: $color-grey-5;
}

.wrapper {
    display: flex;
    padding-left: 7px;
    isolation: isolate;

    .tooltip {
        margin-left: -7px;
    }
    
    .img {
        position: relative;
        width: 28px;
        height: 28px;
        border: 1px solid $color-grey-5;
        border-radius: 50%;
        background-color: $color-grey-3;
        text-align: center;
        font-family: $font-text;
        line-height: 48px;
        font-size: $size-m;
        font-weight: $weight-bold;


        &.more {
            display: flex;
            justify-content: center;
            align-items: center;

            .label {        
                text-align: center;
                font-family: $font-text;
                line-height: 48px;
                font-size: $size-s;
                font-weight: $weight-bold;
            }
        }

        &.grey {
            background: $color-white;
            color: $color-grey-7;
            border-color: $color-grey-7;
        }
        &.orange {
            background: $color-orange-light;
            color: $color-orange-dark;
        }
        &.green {
            background: $color-green-light;
            color: $color-green-dark;
        }
        &.blue {
            background: $color-blue-light;
            color: $color-blue-dark;
        }
        &.lightgrey {
            background: rgb(249, 249, 249);
            color: $color-grey-5;
            border-color: $color-grey-5;
        }
    }
}

</style>