<template>
    <svg :width="width" :height="height" :color="color" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.5" x2="12" y2="0.5" stroke="currentColor"/>
        <line y1="6.5" x2="12" y2="6.5" stroke="currentColor"/>
        <line y1="3.5" x2="12" y2="3.5" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowDown',
    props: {
        color: {
            type: String,
            default: '#373836',
        },
        width: {
            type: [Number, String],
            default: 12,
        },
        height: {
            type: [Number, String],
            default: 7,
        },
    },
};
</script>
