<template>
    <auth-base>
        <div class="q-card-medium">
            <q-section paddingBottom="10" paddingTop="40" hPadding="40" borderBottom>
                <h2>Account aangemaakt</h2>
                <p class="py-s">
                    Het aanmaken van je account is gelukt! Er is een e-mail verzonden met daarin de instructies om je account te <b>bevestigen.</b> Daarna ben je helemaal klaar voor de start. 
                </p>
            </q-section>
            <q-section class="login-form space-between" vPadding="20" hPadding="40">
                <q-button to="/access" variation="blank">Naar mijn account</q-button>
            </q-section>
        </div>
    </auth-base>
</template>

<script>
import AuthBase from '@/pages/auth/AuthBase';

export default {
    name: 'RegisterConfirm',
    components: {
        AuthBase
    }
};
</script>
