<template>
    <div class="page-padding">
        <div class="header">
            <div>
                <arrow-button color="#aeb5bc" @click="$router.push('/settings')" direction="left">Instellingen</arrow-button>
            </div>
            <div class="title-header">
                <h2>Prestaties</h2>
                <div class="button-container">
                    <q-button class="export-button" size="small" variation="light" @click="exportLabels">{{ exportButtonText }}</q-button>
                </div>
            </div>
            <p>Bekijk en wijzig hier de <b>prestaties</b> met daarin de <b>projectkenmerken</b> en <b>vragen</b>. Een prestatie is meestal iets wat je oplevert, maar kan ook gezien worden als een categorie van kenmerken en vragen. De volgorde van prestaties zoals hieronder getoond is de volgorde die wordt gebruikt in nieuwe projecten en is ook de volgorde van de lijst van prestaties, wanneer je een nieuwe prestatie toevoegt aan een bestaand project.</p>
        </div>

        <div class="actions-container">
            <div class="left">
                <q-input v-model="searchingValue" variation="blank" size="medium" placeholder="Typ om te zoeken" :disabled="enableDragLabels" @input="_handleFilterLabels"></q-input>
            </div>
            <div class="right">

                <div class="button-container">
                    <q-button size="medium" variation="blank" icon="SortArrows" iconPosition="right" :disabled="searchingValue.length > 0" @click="toggleLabelSort">{{ enableDragLabels ? 'Wijzigingen annuleren' : 'Volgorde wijzigen' }}</q-button>
                </div>
                <div class="button-container">
                    <q-button size="medium" variation="primary" @click="primaryButtonAction" :loading="primaryButtonLoading">{{ enableDragLabels ? 'Volgorde opslaan' : 'Prestatie toevoegen' }}</q-button>
                </div>
            </div>
        </div>

        <div class="data-container">
            <div class="show-loader" :class="loadingLabels ? 'show' : 'hide'">
                <div class="center">
                    <div class="loader"></div>
                </div>
            </div>

            <context-rows
                v-if="sortedActiveLabels.length > 0"
                :labels="sortedActiveLabels"
                :allLabels="labels"
                :collapsedRowId="collapsedRowId" 
                :loadingComponents="loadingComponents" 
                :dragLabels="enableDragLabels"
                :searchingValue="searchingValue"
                :referencesOnly="!hasPDFull"
                dataName="prestatie"
                contextName="prestatie"
                :dragReferences="enableDragLabels"
                :disableToggleRow="false"
                @toggleShowRow="toggleShowRow"
                @indicatorUpdated="handleIndicatorUpdated"
                @indicatorCreated="handleIndicatorCreated"
                @indicatorDeleted="handleIndicatorDeleted"
                @labelUpdated="handleLabelUpdated"
                @labelDeleted="handleLabelDeleted"
                @orderUpdated="handleLabelOrderUpdated"
                @indicatorOrderUpdated="indicatorOrderUpdated">
            </context-rows>
            <p v-else-if="!loadingLabels" class="template-rows">Er zijn geen actieve prestaties gevonden</p>

            <div v-if="sortedDisabledLabels.length > 0" class="labels-group">
                <h3>Gedeactiveerde prestaties</h3>
                <context-rows
                    :labels="sortedDisabledLabels"
                    :allLabels="labels"
                    :collapsedRowId="collapsedRowId" 
                    :loadingComponents="loadingComponents" 
                    :searchingValue="searchingValue"
                    :referencesOnly="!hasPDFull"
                    :disableToggleRow="enableDragLabels"
                    dataName="prestatie"
                    contextName="prestatie"
                    @toggleShowRow="toggleShowRow"
                    @indicatorUpdated="handleIndicatorUpdated"
                    @indicatorCreated="handleIndicatorCreated"
                    @indicatorDeleted="handleIndicatorDeleted"
                    @labelUpdated="handleLabelUpdated"
                    @labelDeleted="handleLabelDeleted"
                    disabled>
                </context-rows>
            </div>
        </div>

        <create-label-modal 
            v-if="showAddLabelModal" 
            contextName="prestatie"
            @cancel="showAddLabelModal = false" 
            @created="handleLabelCreated" 
            @deleted="handleLabelDeleted"
            onlyNewLabel
        ></create-label-modal>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue';
import ContextRows from './ContextRows.vue';
import CreateLabelModal from './CreateLabelModal.vue';
import gql from 'graphql-tag';
import _ from 'lodash';

import { GET_INDICATORS_WITH_TEMPLATES } from '@/graphql/queries';
import { UPDATE_LABEL, REMOVE_LABEL, UPDATE_LABELS_ORDER, UPDATE_INDICATOR_ORDER } from '@/graphql/mutations';
import { sortIndicators } from '@/assets/js/utils.js';

import { getAnswerTypeLabel } from '@/assets/js/utils.js';

export default {
    name: 'label-management',
    components: {
        'arrow-button': ArrowButton,
        ContextRows,
        CreateLabelModal
    },
    data() {
        return {
            labels: [],
            indicators: [],
            activeLabels: [],
            sortedActiveLabels: [],
            disabledLabels: [],
            sortedDisabledLabels: [],
            collapsedRowId: null,
            references: [],
            questions: [],
            loadingComponents: false,
            enableDragLabels: false,
            searchingValue: '',
            showAddLabelModal: false,
            loadingLabels: false,
            primaryButtonLoading: false,
            changedLabelIndicators: [],
            buildingLabelsCsv: false,
            buildingLabelsProgress: 0
        }
    },
    methods: {
        getDisplayTypeLabel(indicator) {
            const options = [
                {
                    label: 'Tekst',
                    value: 'text'
                },
                {
                    label: 'Getal',
                    value: 'number'
                },
                {
                    label: 'Bedrag',
                    value: 'currency'
                },
                {
                    label: 'Getal + eenheid',
                    value: 'numberUnit'
                },
                {
                    label: 'Schaal',
                    value: 'score'
                },
                {
                    label: 'Enkele keuze',
                    value: 'radio'
                },
                {
                    label: 'Meerkeuze',
                    value: 'checkbox'
                },
                {
                    label: 'Datum',
                    value: 'date'
                },
                {
                    label: 'Bijlage',
                    value: 'upload'
                }
            ]

            return options.find(option => option.value === indicator.displayType || option.value === indicator.answerType)?.label || '';
        },
        getIndicatorLine(indicator) {
            const {
                name,
                description,
                status,
                projecttemplates,
                formtemplates,
                unit,
                answerValues
            } = indicator;

            const nameLabel = (name || '').replace(/;/g, '');
            const descriptionLabel = (description || '').replace(/(\r\n|\n|\r)/gm, ' ').replace(/;/g, '');
            const isActive = status === 'active';
            const projectTemplateNames = (projecttemplates || []).map(template => this.$t(template.name)).join(', ');
            const formTemplateNames = (formtemplates || []).map(template => this.$t(template.name)).join(', ');
            const displayTypeLabel = this.getDisplayTypeLabel(indicator);
            const answerValueValues = (answerValues || []).map(value => value.label).join(', ');
            const required = indicator.required ? 'Ja' : 'Nee';
            const mandatory = indicator.mandatory ? 'Ja' : 'Nee';
            const canFillComment = indicator.canFillComment ? 'Ja' : 'Nee';

            return `;${nameLabel};${descriptionLabel};${isActive ? 'Ja' : 'Nee'};${projectTemplateNames};${formTemplateNames};${displayTypeLabel};${unit || ''};${answerValueValues};${required};${mandatory};${canFillComment}`;
        },
        async exportLabels() {
            try {
                const this_ = this;
                const organisationId = this.$store.getters.getCurrentOrganisation.id

                async function getLabelIndicators(labelToExport) {
                    let attempts = 0;

                    try {
                        attempts += 1;

                        return await this_.$apollo.query({
                            query: GET_INDICATORS_WITH_TEMPLATES,
                            variables: {
                                organisationId,
                                where: {
                                AND: [
                                    { labelIds: labelToExport.id },
                                    { status__in: ["active"] }
                                ]
                                },
                                first: 100,
                                sort: 'index__ASC'
                            },
                            fetchPolicy: 'no-cache'
                        });
                    } catch (error) {
                        if (attempts >= 3) throw error;
                        
                        await new Promise(r => setTimeout(r, 5000))

                        return getLabelIndicators(labelToExport)
                    }
                }

                if (this.buildingLabelsCsv) return;

                this.buildingLabelsCsv = true;
                this.buildingLabelsProgress = 0;
                
                const rows = [];

                rows.push('Actieve prestaties')

                const labelsToExport = this.activeLabels;
                const stepAmount = .95 / labelsToExport.length;

                for (let i = 0; i < labelsToExport.length; i++) {
                    const labelToExport = labelsToExport[i];

                    rows.push(labelToExport.name)
                    
                    const result = await getLabelIndicators(labelToExport)

                    const indicators = result.data.indicators_with_templates;
                    const references = indicators.filter(indicator => indicator && indicator.type === 'reference');
                    const questions = indicators.filter(indicator => indicator && indicator.type === 'question');

                    
                    if (references.length > 0) {
                        rows.push(';Kenmerken')
                    }

                    for (let j = 0; j < references.length; j++) {
                        rows.push(this.getIndicatorLine(references[j]));
                    }

                    
                    if (questions.length > 0) {
                        rows.push(';Vragen')
                    }

                    for (let j = 0; j < questions.length; j++) {
                        rows.push(this.getIndicatorLine(questions[j]));
                    }

                    rows.push('');

                    this.buildingLabelsProgress += stepAmount;
                }

                const deactivatedLabels = this.disabledLabels;
                const deactivatedStepAmount = .05 / labelsToExport.length;

                rows.push('Gedeactiveerde prestaties')

                if (deactivatedLabels.length === 0) {
                    rows.push('Geen gedeactiveerde prestaties');
                }

                for (let i = 0; i < deactivatedLabels.length; i++) {
                    const labelToExport = deactivatedLabels[i];

                    rows.push(labelToExport.name)
                    
                    const result = await getLabelIndicators(labelToExport)

                    const indicators = result.data.indicators_with_templates;
                    const references = indicators.filter(indicator => indicator.type === 'reference');
                    const questions = indicators.filter(indicator => indicator.type === 'question');

                    
                    if (references.length > 0) {
                        rows.push(';Kenmerken')
                    }

                    for (let j = 0; j < references.length; j++) {
                        rows.push(this.getIndicatorLine(references[j]));
                    }

                    
                    if (questions.length > 0) {
                        rows.push(';Vragen')
                    }

                    for (let j = 0; j < questions.length; j++) {
                        rows.push(this.getIndicatorLine(questions[j]));
                    }

                    rows.push('')

                    this.buildingLabelsProgress += deactivatedStepAmount;
                }

                const headerRow = 'Prestatie;Naam;Omschrijving;Actief (Ja/Nee);Projecttemplates;Formuliertemplates;Antwoordtype;Eenheid;Antwoordopties;Verplicht;Vereist;Opmerking (Ja/Nee)';
                const csvRows = [headerRow, ...rows].join('\n');

                const BOM = '\uFEFF';
                const csvContent = BOM + csvRows;

                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

                this.buildingLabelsProgress = 1;
                this.buildingLabelsCsv = false;

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', `export_${new Date().toLocaleDateString('nl-NL')}.csv`);
                link.click();
            } catch(error) {
                console.error(error);
                console.error(error.stack);
                this.buildingLabelsProgress = 1;
                this.buildingLabelsCsv = false;
                
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het exporteren van de prestaties' });
            }
        },
        getLabels() {
            this.loadingLabels = true;

            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.$apollo.query({
                query: gql`
                    query getLabels($organisationId: String!, $first: Int!) {
                        labels(first:$first, where: { AND: [
                                { organisationId: $organisationId },
                                { status__in: ["active", "disabled"] },
                                { name__nin: ["Project_details","Crow_project_details"] },
                                { masterId__not_contains: "crow" },
                                { id__not_contains: "crow" }
                            ]}, sort: ["order__ASC"]) {
                            id
                            name
                            description
                            masterId
                            status
                            order
                        }
                    }
                `,                
                variables: {
                    organisationId,
                    first: 500
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const translatedNames = ['Project_details', 'Crow_project_details'];
                const parsedLabels = result.data.labels.map(label => {
                    if(translatedNames.includes(label.name)) label.name = this.$t(label.name);
                    label.references = [];
                    label.questions = [];
                    return label
                });
                this.labels = parsedLabels;
                this.parseLabels();
                this.loadingLabels = false;
            })
            .catch(error => {
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de prestaties' })
                this.loadingLabels = false;
            })
        },
        parseLabels() {
            const translatedNames = ['Project_details', 'Crow_project_details'];
            this.indicators = sortIndicators(this.indicators);
            this.labels = this.labels.sort((a,b) => a.order - b.order)

            this.labels = this.labels.map(label => {
                if(translatedNames.includes(label.name)) label.name = this.$t(label.name);
                const references = this.indicators.filter(indicator => indicator.type === 'reference' && indicator.labelIds.includes(label.id));
                const questions = this.indicators.filter(indicator => indicator.type === 'question' && indicator.labelIds.includes(label.id));

                return {
                    ...label,
                    questions: this.parseQuestions(questions),
                    references: this.parseQuestions(references)
                }
            });
            
            const activeLabels = this.labels.filter(label => label.status === 'active');
            const disabledLabels = this.labels.filter(label => label.status === 'disabled');

            this.activeLabels = [...activeLabels];
            this.sortedActiveLabels = [...activeLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
            this.disabledLabels = [...disabledLabels];
            this.sortedDisabledLabels = [...disabledLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
        },
        toggleShowRow(row) {
            if(this.collapsedRowId !== row.id) {
                this.collapsedRowId = row.id;
                this.getComponents();
            }
            else this.collapsedRowId = null;
        },
        handleLabelUpdated(label) {
            this.$apollo.mutate({
                mutation: UPDATE_LABEL,
                variables: {
                    id: label.id,
                    name: label.name,
                    description: label.description,
                    status: label.status
                }
            })
            .then(result => {
                this.labels = this.labels.map(_label => {
                    if(_label.id === label.id) return label
                    return _label
                })
                this.parseLabels();
                this.$store.commit('notify', { type: 'success', message: 'De wijzigingen zijn doorgevoerd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de wijzigingen' })
            })
        },
        handleLabelDeleted(label) {
            this.labels.map(_label => {
                if (_label.id === label.id) _label.loading = true;
                return _label; 
            });

            this.parseLabels();
            
            this.$apollo.mutate({
                mutation: REMOVE_LABEL,
                variables: {
                    id: label.id
                }
            })
            .then(() => {
                this.labels = this.labels.filter(_label => _label.id !== label.id);
                this.parseLabels();
                this.$store.commit('notify', { type: 'success', message: 'Prestatie is succesvol verwijderd' })
            })
            .catch(error => {
                this.labels.map(_label => {
                    if (_label.id === label.id) _label.loading = false;
                    return _label; 
                });
                this.parseLabels();
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van de prestatie' })
            })
        },
        handleIndicatorUpdated(indicator) {
            const indicatorIndex = this.indicators.findIndex(_indicator => _indicator.id === indicator.id);
            if(indicatorIndex === -1) return
            
            this.indicators[indicatorIndex] = indicator;

            this.parseLabels();
        },
        handleIndicatorCreated(indicator) {
            this.indicators.push(indicator);
            this.parseLabels();
        },
        handleIndicatorDeleted(indicator) {
            const indicatorIndex = this.indicators.findIndex(_indicator => _indicator.id === indicator.id);
            if(indicatorIndex === -1) return
            this.indicators.splice(indicatorIndex, 1);

            this.parseLabels();
        },
        async getComponents() {
            const label = this.labels.find(label => label.id === this.collapsedRowId);
            if(!label || label.questions.length || label.references.length) return

            this.loadingComponents = true;

            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.$apollo.query({
                query: GET_INDICATORS_WITH_TEMPLATES,
                variables: {
                    organisationId,
                    where: {
                        AND: [
                            { labelIds: label.id },
                            { status__in: ["active", "disabled"] }
                        ]
                        
                    }
                },
                fetchPolicy: 'no-cache'
            })
            .then(async result => {
                const resultIndicators = result.data.indicators_with_templates;
                this.indicators = resultIndicators;
                
                this.parseLabels();
                
                this.loadingComponents = false;

                this.scrollToLabel(label.id);
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de kenmerken en vragen' })
                this.loadingComponents = false;
            })
        },
        parseQuestions(questions) {
            const mappedQuestions = questions.map(question => {
                question.answerTypeLabel = getAnswerTypeLabel(question.displayType);
                question.enabled = question.enabled ?? true;
                question.required = question.required ?? true;
                question.mandatory = question.mandatory ?? true;
                question.canFillComment = question.canFillComment ?? true;
                question.canFillNotApplicable = question.canFillNotApplicable ?? true;
                if(question.status !== 'active') question.__customStyling = 'color: #757575;';
                else question.__customStyling = null;
                return question
            })
            const sortedQuestions = sortIndicators(mappedQuestions)
            return sortedQuestions
        },
        toggleLabelSort() {
            if(this.enableDragLabels) {
                this.sortedActiveLabels = this.activeLabels;
                this.changedLabelIndicators = []
                this.enableDragLabels = false;
                this.parseLabels()
            } else {
                this.enableDragLabels = true;

                if (this.collapsedRowId === null)
                    this.collapsedRowId = this.sortedActiveLabels[0].id

                this.getComponents();
            }

        },
        async primaryButtonAction() {
            if(this.enableDragLabels) {
                try {
                    this.enableDragLabels = false;
                    this.primaryButtonLoading = true;
                    await this.handleSaveLabelOrder();
                    await this.saveIndicatorOrder();

                    this.activeLabels = this.sortedActiveLabels;

                    this.parseLabels();
                    
                    this.primaryButtonLoading = false;

                    this.$store.commit('notify', { type: 'success', message: 'Volgorde is opgeslagen' });
                } catch(error) {
                    console.log(error);
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de volgorde' });
                }

            } else {
                this.showAddLabelModal = true;
            }

        },
        handleLabelOrderUpdated(labels) {
            this.sortedActiveLabels = labels;
        },
        handleSaveLabelOrder() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const labelIds = this.sortedActiveLabels.map(label => label.id);

            this.sortedActiveLabels.forEach((label, index) => {
                label.order = index;
            })
            return this.$apollo.mutate({
                mutation: UPDATE_LABELS_ORDER,
                variables: {
                    organisationId,
                    oldLabelIds: this.labels.map(label => label.id), 
                    newLabelIds: labelIds
                }
            })
        },
        _handleFilterLabels: _.debounce(function() {
            this.handleFilterLabels()
        }, 250),
        handleFilterLabels() {
            this.sortedActiveLabels = [...this.activeLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
            this.sortedDisabledLabels = [...this.disabledLabels].filter(label => label.name.toLowerCase().includes(this.searchingValue.toLowerCase()))
        },
        async handleLabelCreated(label) {
            label.questions = [];
            label.references = [];
            this.labels.push(label);
            this.parseLabels();
            this.collapsedRowId = label.id;
            this.showAddLabelModal = false;
            this.scrollToLabel(label.id);
        },
        async scrollToLabel(rowId) {
            await new Promise(r => setTimeout(r, 200));
            const element = document.getElementById(`row-${rowId}`);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        indicatorOrderUpdated({ labelId, indicators }) {
            const labelIndex = this.labels.findIndex(label => label.id === labelId);
            const labelHasChangesIndex = this.changedLabelIndicators.findIndex(label => label.id === labelId);

            if(labelHasChangesIndex === -1) {
                this.changedLabelIndicators.push(this.labels[labelIndex]);
            } else {
                this.changedLabelIndicators[labelHasChangesIndex] = this.labels[labelIndex];
            }
        },
        saveIndicatorOrder() {

            const labelsToUpdate = this.changedLabelIndicators.map(label => {
                const indicators = [...label.references.map(reference => reference.id), ...label.questions.map(question => question.id)]

                const labelIndex = this.labels.findIndex(_label => _label.id === label.id);

                this.labels[labelIndex].references.forEach((reference, index) => {
                    reference.index = index
                });

                this.labels[labelIndex].questions.forEach((question, index) => {
                    question.index = index
                });

                return {
                    id: label.id,
                    indicators
                }
            });

            return this.$apollo.mutate({
                mutation: UPDATE_INDICATOR_ORDER,
                variables: {
                    labels: labelsToUpdate
                }
            })
        }
    },
    computed: {
        exportButtonText: function() {
            if (this.buildingLabelsCsv) {
                return `${Math.round(this.buildingLabelsProgress * 100)}%`;
            } else {
                return 'Prestaties exporteren';
            }
        },
        hasPDFull: function() {
            return this.$store.getters.hasPDFullProduct
        }
    },
    created() {
        this.getLabels();
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.page-padding {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 6px;

    .left {
        min-width: 300px;
    }

    .right {
        display: flex;
        gap: 30px;
    }
}

.labels-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 36px;
}

.show-loader {
    overflow: hidden;
    transition: max-height .2s ease;

    &.show {
        max-height: 120px;
    }
    &.hide {
        max-height: 0;
        .center .loader {
            transition: .2s ease;
            scale: 0.5;
            opacity: 0;
        }
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 40px;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 40px;
    $loader-border-size: 3px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

.export-button {
    width: 150px;
}

.title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>