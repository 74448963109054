<template>
    <div class="enquete-question">
        <div class="left">
            <div class="header">
                <q-tooltip position="top" :disabled="!indicator.mandatory">
                    <template v-slot:tooltip>
                        <p>Deze vraag is vereist om te stellen bij het uitsturen van je formulier</p>
                    </template>
                    <q-switch v-if="editing" v-model="indicator.enabled" :disabled="indicator.mandatory" @input="toggleActive"></q-switch>
                </q-tooltip>
                <div class="label-container">
                    <span class="label">{{ questionLabel }}</span>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="header">
                <p class="title">{{ indicator.name }}<span v-if="indicator.required" class="required">*</span></p>
                <span v-if="editing && !indicator.isDefaultQuestion" contenteditable type="text" class="q-clear-input" placeholder="Typ hier toelichting op de vraag" @input="handleDescriptionInput">{{ initialDescription }}</span>
                <p v-else-if="indicator.description" v-html="indicator.description"></p>
            </div>

            <div class="content" :class="indicator.answer === -1 ? 'hide' : 'show'">
                <div v-if="indicator.displayType === 'checkbox'" class="question-type">
                    <q-checkbox-options
                        :options="indicator.answerValues"
                        :disabled="editing"
                        :value="indicator.answer"
                        @input="handleCheckboxInput">
                    </q-checkbox-options>
                </div>
                <div v-else-if="indicator.displayType === 'radio'" class="question-type">
                    <q-radio-options
                        :options="indicator.answerValues"
                        :disabled="editing"
                        :value="indicator.answer"
                        @input="handleRadioInput">
                    </q-radio-options>
                </div>
                <div v-else-if="indicator.displayType === 'score'" class="question-type">
                    <q-score-options
                        v-if="indicator.showScore"
                        :options="indicator.answerValues"
                        :disabled="editing"
                        :value="indicator.answer"
                        :showScore="indicator.showScore"
                        @input="handleScoreInput">
                    </q-score-options>
                    <q-radio-options
                        v-else
                        :options="indicator.answerValues"
                        :disabled="editing"
                        :value="indicator.answer"
                        singleRow
                        @input="handleScoreInput">
                    </q-radio-options>
                </div>
                <div v-else-if="indicator.displayType === 'number'" class="question-type">
                    <q-input v-model="answerValue" @input="_emitInput" :inputId="indicator.componentId" :disabled="editing" inputType="number" :min="0"></q-input>
                </div>
                <div v-else-if="indicator.displayType === 'numberUnit'" class="question-type">
                    <q-input v-model="answerValue" @input="_emitInput" :inputId="indicator.componentId" :disabled="editing" inputType="number" :unit="indicator.unit"></q-input>
                </div>
                <div v-else-if="indicator.displayType === 'currency'" class="question-type">
                    <q-input v-model="answerValue" @input="_emitInput" :inputId="indicator.componentId" :disabled="editing" inputType="currency"></q-input>
                </div>
                <div v-else-if="indicator.displayType === 'text'" class="question-type">
                    <q-input v-model="answerValue" @input="_emitInput" :disabled="editing"></q-input>
                </div>
                <div v-else-if="indicator.displayType === 'email'" class="question-type">
                    <q-input v-model="answerValue" @input="_emitInput" @error="E => $emit('error', E)" inputType="email" :disabled="editing"></q-input>
                </div>
                <div v-else-if="indicator.displayType === 'upload'" class="question-type">
                    <q-button :disabled="editing">Bijlage uploaden</q-button>
                </div>
                <div v-else-if="indicator.displayType === 'date'" class="question-type">
                    <el-date-picker
                        class="datepicker"
                        v-model="indicator.answer"
                        :disabled="editing"
                        type="date"
                        size="large"
                        :default-value="new Date()"
                        value-format="timestamp"
                        format="dd-MM-yyyy"
                        @blur="emitInput"
                        placeholder="Kies een datum"
                        ref="focus"
                    >
                    </el-date-picker>
                </div>
            </div>

            <div class="footer" v-if="!editing && !indicator.isDefaultQuestion">
                <div v-if="indicator.canFillNotApplicable" class="nvt-option">
                    <q-checkbox :value="Boolean(indicator.answer === -1)" @input="handleSkip">Niet van toepassing</q-checkbox>
                </div>
                <div v-if="indicator.canFillComment" class="add-comment" :class="{ active: showComment }" @click="toggleShowComment">
                    <q-icon class="icon" type="AddTextBubble" color="#ADB5BD" width="20" height="17"></q-icon>
                    <p>opmerking</p>
                </div>
            </div>

            <div v-if="indicator.canFillComment" class="comment" :class="showComment ? 'show' : 'hide'">
                <q-text v-model="indicator.comment" @input="handleCommentInput"></q-text>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { isValidAnswer } from '../../../assets/js/utils';

export default {
    name: 'enquete-question',
    props: {
        // v-model
        value: {
            required: true
        },
        indicator: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        editing: {
            type: Boolean,
            default: false
        },
        // custom label instead of the 'Vraag x' label
        customLabel: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showComment: false,
            comment: '',
            initialDescription: '',
            answerValue: null
        }
    },
    methods: {
        _handleDescriptionInput: _.debounce(function(event) {
            const description = event.target.innerText;
            this.indicator.description = description;
            this.$emit('descriptionInput', this.indicator)
            this.$emit('waitingForSave', false)
        }, 200),
        handleDescriptionInput(event) {
            this.$emit('waitingForSave', true);
            this._handleDescriptionInput(event)
        },
        _handleCommentInput() {
            this.$emit('waitingForSave', false);
            this.emitInput();
        },
        handleCommentInput() {
            this.$emit('waitingForSave', true);
            this._handleCommentInput();
        },
        handleCheckboxInput(checked, value) {
            let answer = this.indicator.answer || [];
            if(answer === -1) answer = [];
            if(checked) answer.push(value);
            else {
                const valueIndex = answer.indexOf(value);
                answer.splice(valueIndex, 1);
            }
            this.indicator.answer = answer;
            this.emitInput();
        },
        handleRadioInput(value) {
            this.indicator.answer = value;
            this.emitInput();
        },
        handleScoreInput(value) {
            if(this.editing) return
            this.indicator.answer = value;
            this.emitInput();
        },
        getEmptyValue(answerType) {
            if(answerType === 'checkbox') return []
            else if(answerType === 'text') return''
            else if(['radio','number'].includes(answerType)) return null
        },
        hasSelectedValue(value) {
            if(!isValidAnswer(this.value) || this.value === -1) return false
            return this.value.includes(value)
        },
        handleSkip(checked) {
            let emptyAnswer
            if(checked) {
                emptyAnswer = -1;
            } else {
                emptyAnswer = this.getEmptyValue();
            }
            this.answerValue = null;
            this.indicator.answer = emptyAnswer;
            this.emitInput();
        },
        emitInput() {
            this.$emit('input', this.indicator);
        },
        _emitInput() { 
            this.indicator.answer = this.answerValue;
            this.emitInput();
        },
        toggleActive() {
            this.$emit('toggleActive', this.indicator);
        },
        toggleShowComment() {
            this.showComment = !this.showComment;
            if(!this.showComment) this.indicator.comment = '';
        }
    },
    computed: {
        questionLabel: function() {
            if(this.customLabel) return this.customLabel
            return this.indicator.enabled ? `Vraag ${this.index}` : 'Inactief'
        }
    },
    created() {
        this.initialDescription = this.indicator.description;
    },
    watch: {
        'indicator.value': function() {
            this.answerValue = this.indicator.value;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

@media only screen and (max-width: 475px) {
    .enquete-question {
        width: calc(100% - 10vw) !important;
        margin-top: 40px;
        .left {
            translate: 0 !important;
            left: -28px !important;
            top: -40px;
        }
    }
}

.enquete-question {
    --gap-width: 20px;

    position: relative;
    display: flex;
    gap: var(--gap-width);
    max-width: 800px;

    .left {
        position: absolute;
        translate: -100% 0;
        left: -14px;

        &:empty {
            display: none;
        }
        .header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 14px;

            .label-container {
                display: grid;
                place-items: center right;
                min-width: 60px;

                .label {
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 16px;
                    background: #f2f2f2;
                    border: 1px solid #dee2e6;
                    border-radius: 4px;
                    color: $color-grey-7;
                    padding: 4px;
                    user-select: none;
                    cursor: default;
                    white-space: nowrap;
                }
            }
        }

    }
    .right {
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex-grow: 1;

        .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }

        .title {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            color: $color-grey-9;
            white-space: pre-wrap;
            width: fit-content;

            .required {
                color: $color-blue;
            }
        }

        .q-clear-input {
            position: relative;
            width: fit-content;
            min-width: 300px;
            line-height: 28px;
            font-size: 14px;

            &:hover, &:focus, &:active {
                border-color: $color-blue !important;
            }

            &:empty {
                border-color: #DEE2E6;

                &:before {
                    content:"Typ hier toelichting op de vraag";
                    position: absolute;
                    left: 0px;
                    color: #ADB5BD;
                    pointer-events: none;
                }
            }
                
        }

        .content {

            .text-area {
                overflow: hidden;

                &.hide {
                    max-height: 0;
                    opacity: 0;
                }

                &.show {
                    --max-height: 120px;
                    animation: maxHeightShow .3s ease;
                    max-height: unset;
                    opacity: 1;
                }
            }
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .add-comment {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 4px 8px;
                color: #ADB5BD;
                border-radius: 4px;
                cursor: pointer;
                transition: scale .3s cubic-bezier(.51,2,.45,1.08), background-color .2s ease;
                user-select: none;

                &:hover {
                    scale: 1.02;
                }
                &.active {
                    background-color: #f8f8f8;
                }

                &:active, &:focus {
                    scale: 0.97;
                }

                p {
                    margin-bottom: 2px;
                }
            }
        }

        .comment {
            overflow: hidden;

            &.hide {
                max-height: 0;
                opacity: 0;
            }

            &.show,
            &:focus-within {
                --max-height: 120px;
                animation: maxHeightShow .3s ease;
                max-height: unset;
                opacity: 1;
            }
        }

    }
}

.options-container {
    --gap: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);
    width: 100%;

    .option {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: #F5F5F5;
        border-radius: 3px;
        width: calc(50% - (var(--gap) / 2) - 32px);
        padding: 14px 16px;
        cursor: pointer;
        transition: all .2s ease, scale .2s cubic-bezier(.51,1.7,.45,1.08);

        &.disabled {
            scale: 1 !important;
            cursor: default;
        }

        &:hover {
            background-color: #e0eeee;
            scale: 1.01;
        }

        &:active, &:focus {
            scale: 0.98;
            background-color: #D2EEF0;
        }

        &.selected {
            background-color: #D2EEF0;
            color: $color-blue;
        }
    }
}

.scores-container {
    display: flex;
    flex-direction: column;

    .score-wrapper {
        display: flex;
        align-items: center;
        padding-block: 4px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        transition: all .2s ease, scale .2s cubic-bezier(.51,2,.45,1.08);

        &.disabled {
            .score {
                scale: 1 !important;
            }
            cursor: default;
        }

        &:hover {
            .label {
                background-color: #e0eeee99;
            }
            .score {
                scale: 1.07;
            }
        }

        &:active,
        &:focus {
            .score {
                scale: 1;
            }
        }

        .score {
            min-width: 44px;
            min-height: 44px;
            border: 1px solid $color-blue;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 13px;
            color: $color-blue;
            transition: all .2s ease, scale .3s cubic-bezier(.51,2,.45,1.08);
        }

        .label {
            padding: 10px 14px;
            border-radius: 0 6px 6px 0;
            flex-grow: 1;
            transition: background-color .2s ease;
        }

        &.selected {
            .label {
                background-color: #e0eeee99;
                color: $color-blue;
                font-weight: 500;
            }

            .score {
                background-color: white;
                scale: 1.07;
                font-size: 16px;
            }
        }
    }

    &:not(.disabled) {
        &:hover, &.has-selected {
            .score-wrapper:not(.selected) .score {
                background-color: #e0eeee;
            }
            .score-wrapper:hover .score {
                background-color: white;
            }
        }
    }
}

@keyframes maxHeightShow {
    0% {
        max-height: 0px;
        opacity: 0;
    } 100% {
        max-height: var(--max-height);
        opacity: 1;
    }
}

</style>