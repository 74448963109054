<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M1.185,1.185l11.63,11.63M1.185,12.815l11.63,-11.63" 
      :stroke="color" 
      stroke-width="2" 
      stroke-linecap="round" 
      stroke-linejoin="round" 
      :width="width"
      :height="height"
    />
  </svg>
</template>

<script>
export default {
  name: "close",
  props: {
    color: {
      type: String,
      default: "#495057",
    },
    width: {
      type: [Number, String],
      default: 14,
    },
    height: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>

<style></style>
